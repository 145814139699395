import React, { useState, useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import Card from '../../../components/card'
import FundingRequestCard from './FundingRequestCard'
import Message from '../../../components/message'
import { useGraphData } from '../../../useGraphData'
import { GET_FUNDING_REQUEST_BY_STATUS } from '../../../components/graphql/queries'
import { FR_UPDATE } from '../../../components/graphql/mutations'
import FundingRequestPopup from '../fr-pop-up'
import {
  type PopupPropsFR,
  type FRprops,
  type FundingRequestSum,
  type OnActionFunction,
  type HandleStatusChangeProps
} from '../utils/types'
import { useQueryWithRetry } from '../../../hooks/useQueryWithRetry'

const FundingRequest: React.FC<FRprops> = ({ status, stage }) => {
  const [message, setMessage] = useState<string | null>(null)
  const [graphData] = useGraphData()
  const userMail = graphData?.mail
  const [messageColor, setMessageColor] = useState<'red' | 'green' | 'yellow'>('green')
  const [primaryLoading, setPrimaryLoading] = useState(false)
  const [secondaryLoading, setSecondaryLoading] = useState(false)

  const { data, refetch, QueryWithRetryComponent, loading, error } = useQueryWithRetry({
    query: GET_FUNDING_REQUEST_BY_STATUS,
    options: { variables: { status, from_date: '2024-01-01' } }
  })

  const [submitFRStatusUpdate] = useMutation(FR_UPDATE)

  const handleStatusChange = useCallback(async ({ variables, actionType }: HandleStatusChangeProps) => {
    setPrimaryLoading(actionType === 'primary')
    setSecondaryLoading(actionType !== 'primary')

    try {
      const { data: responseData } = await submitFRStatusUpdate({ variables })
      const { status: responseStatus, error: responseError } = responseData.updateFRStatus
      const successMessage = actionType === 'primary'
        ? 'Successfully approved a FR, please check your email'
        : 'Funding request rejected successfully'
      setMessage(responseStatus ? successMessage : (responseError ?? 'Unknown error'))
      setMessageColor(responseStatus ? 'green' : 'red')
    } catch (error) {
      setMessage('Error updating status.')
      setMessageColor('red')
    } finally {
      refetch()
      setPrimaryLoading(false)
      setSecondaryLoading(false)
    }
  }, [submitFRStatusUpdate, refetch])

  const handleAction: OnActionFunction = useCallback(async ({ actionType, fundingRequestADCId, noteText }) => {
    const statusList = {
      Initial: { primary: ['NEW', 'INIT_APPROVE'], secondary: ['NEW', 'REJECT'] },
      Final: { primary: ['INIT_APPROVE', 'APPROVE'], secondary: ['INIT_APPROVE', 'REJECT'] }
    }

    const [oldStatus, newStatus] = statusList[stage][actionType]
    const variables = {
      fr_id: fundingRequestADCId,
      updated_by: userMail,
      old_status: oldStatus,
      new_status: newStatus,
      note: noteText
    }

    await handleStatusChange({ variables, actionType })
  }, [handleStatusChange, stage, userMail])

  const renderPopupComponent = useCallback(
    (fundingRequestId: number, popupProps: PopupPropsFR) => {
      return (
        <FundingRequestPopup
          fundingRequestADCId={fundingRequestId}
          setMessage={setMessage}
          setMessageColor={setMessageColor}
          stage={stage}
          updateError={error}
          onPrimaryAction={async (args) => { await handleAction(args) }}
          onSecondaryAction={async (args) => { await handleAction(args) }}
          primaryLoading={primaryLoading}
          secondaryLoading={secondaryLoading}
          userEmail={userMail}
          {...popupProps}
        />
      )
    },
    [handleAction, error, stage, primaryLoading, secondaryLoading]
  )

  const sortedFundingRequests = useMemo(
    () => [...(data?.getFundingRequestByStatus || [])].sort((a: FundingRequestSum, b: FundingRequestSum) =>
      new Date(b.timeSubmitted).getTime() - new Date(a.timeSubmitted).getTime()
    ),
    [data]
  )

  if (error ?? loading) return <QueryWithRetryComponent />

  return (
    <>
      {message && <Message message={message} color={messageColor} clearMessage={() => { setMessage('') }} />}
      <div className="flex flex-wrap justify-around">
        {sortedFundingRequests.length > 0
          ? sortedFundingRequests.map((fundingRequest) => (
            <Card
              key={fundingRequest.fundingRequestADCId}
              refetch={refetch}
              CardBodyComponent={(cardBodyProps) => (
                <FundingRequestCard data={fundingRequest} status={fundingRequest.status} {...cardBodyProps} />
              )}
              PopupComponent={(popupProps) => renderPopupComponent(fundingRequest.fundingRequestADCId, popupProps)}
            />
          ))
          : <p>No data found</p>}
      </div>
    </>
  )
}

export default React.memo(FundingRequest)
