import React from 'react'
import { type CalculatedFieldsDisplayProps } from '../utils/types'
import { formatInputToDollar, parseDollarToNumber } from '../../../utility/formatting'

const replaceNaNWithZero = (value: number): number => {
  return isNaN(value) ? 0 : value
}

const CalculatedFieldsDisplay: React.FC<CalculatedFieldsDisplayProps> = ({
  allocationAmount,
  discountRate,
  tenor,
  programExpenseMultiplier,
  isSum = false
}) => {
  const originalIssueDiscount = replaceNaNWithZero(
    (allocationAmount * discountRate * tenor) / 36000
  )
  const proceeds = replaceNaNWithZero(allocationAmount - originalIssueDiscount)
  const originalIssueDiscountPlusProgramExpense = replaceNaNWithZero(
    proceeds * programExpenseMultiplier * tenor / 360 + originalIssueDiscount
  )
  const mmYield = replaceNaNWithZero(
    (originalIssueDiscountPlusProgramExpense * 36000) / (proceeds * tenor)
  )

  return (
    <div className="space-y-1 font-semibold text-sm bg-slate-100">
      <div>Proceeds: {formatInputToDollar(proceeds, { prefix: '$' })}</div>
      <div>
        Original Issue Discount: {formatInputToDollar(parseDollarToNumber(originalIssueDiscount), { prefix: '$' })}
      </div>
      <div>
        Original Issue Discount + Program Expense: {formatInputToDollar(parseDollarToNumber(originalIssueDiscountPlusProgramExpense), { prefix: '$' })}
      </div>
      {!isSum && <div>Interest Rate(mmy): {mmYield.toFixed(4)}%</div>}
    </div>
  )
}

export default CalculatedFieldsDisplay
