import { gql } from '@apollo/client'
import { CP_FRAGMENT, FUNDING_REQUEST_FRAGMENT, RAW_ASSET_SUM_FRAGMENT, ISSUER_FRAGMENT, FR_ATTACHED_ASSET_SUM_FRAGMENT, FUNDING_REQUEST_SUM_FRAGMENT, FR_RECONCILE_ASSETD_DETAILS_RRAGMENT, FR_RECONCILE_SUMMARY_DATA_RRAGMENT, RAW_ASSET_DETAILS_DATA_FRAGMENT, RAW_ASSET_DETAILS_SUMMARY_FRAGMENT, RAW_ASSET_NOTE_FRAGMENT } from '../fragments'

export const GET_CP_BY_DATES = gql`
    ${CP_FRAGMENT}
    query CPIssuanceByDates($from_date: String, $to_date: String) {
        getCPIssuanceByDates(fromDate: $from_date, toDate: $to_date) {
          cpData{
            ...CPFragment
          }
          frData{
            cPIssuanceId
            fundingRequestCode
            cpNotionalQtyAllocated
          }
        }
    }
`
export const GET_CP_BY_ID = gql`
    ${CP_FRAGMENT}
    query CPIssuanceById($id: String!, $data_type: String) {
      getCPIssuanceById(id: $id, dataType: $data_type) {
          cpData{
            ...CPFragment
          }
          frData{
            cPIssuanceId
            fundingRequestCode
            cpNotionalQtyAllocated
          }
        }
    }
`
export const GET_FUNDING_REQUEST_BY_STATUS = gql`
  ${FUNDING_REQUEST_SUM_FRAGMENT}
  query FundingRequestByStatus($status: String!, $from_date: String!) {
    getFundingRequestByStatus(status: $status, fromDate: $from_date) {
      ...FundingRequestSumFragment
    }
  }
`

export const GET_FUNDING_REQUEST_BY_ID = gql`
  ${FUNDING_REQUEST_FRAGMENT}
  ${RAW_ASSET_NOTE_FRAGMENT}
  query FundingRequestById($fundingRequestId: Int!, $note_user: String!) {
    getFundingRequestByID(fundingRequestId: $fundingRequestId, noteUser: $note_user) {
      fundingRequestDetails {
        ...FundingRequestFragment
      }
      noteData {
        ...RawAssetNoteDataFragment
      }
    }
  }
`

export const GET_RAW_ASSET_DETAILS_BY_ID = gql`
  ${RAW_ASSET_NOTE_FRAGMENT}
  ${RAW_ASSET_DETAILS_DATA_FRAGMENT}
  ${RAW_ASSET_DETAILS_SUMMARY_FRAGMENT}
  query GetRawAssetDetailsByBatchId(
      $batch_unique_id: String!,
      $note_user: String,
      $return_result_option: String
    ) {
    getRawAssetDetailsByBatchId(
        batchUniqueID: $batch_unique_id,
        noteUser: $note_user, 
        returnResultOption: $return_result_option
      ) {
      status
      message
      detailsData {
        ...RawAssetDetailsDataFragment
      }
      summaryData {
        ...RawAssetDetailsSummaryFragment
      }
      noteData {
        ...RawAssetNoteDataFragment
      }
    }
  }
`

export const GET_ASSET_SUM_BY_DATE = gql`
  ${RAW_ASSET_SUM_FRAGMENT}
  query GetRawAssetSumByDates($startDate: String!) {
    getRawAssetSumByDates(startDate: $startDate) {
      ...RawAssetSumFragment
    }
  }
`

export const GET_ALL_ISSUERS = gql`
  ${ISSUER_FRAGMENT}
  query getAllIssuers($test: Int) {
    getAllIssuers(test: $test) {
      ...IssuerFragment
    }
  }
`

export const GET_RATINGS = gql`
  query GetRatingListByVendor($ratingVendor: String!) {
    getRatingListByVendor (ratingVendor: $ratingVendor) {
      rating
      status 
      message
    }
  }
`
export const GET_AVALIBLE_ASSETS_DATE = gql`
  query GetAvailabilityForAssets($process: String!) {
    getAvailabilityForAssets (process: $process) {
      error
      status 
      trade_dates
    }
  }
`

export const GET_AVALIBLE_FR_WITH_COUNTERPARTY_ID = gql`
  query GetFundingRequestOptionsByCounterpartyId($counterpartyId: Int!) {
    getFundingRequestOptionsByCounterpartyId (counterpartyId: $counterpartyId) {
      error
      status 
      fr_options {
        frID
        frCode
      }
    }
  }
`

export const GET_ASSETS_WITH_FR_ID = gql`
  ${RAW_ASSET_DETAILS_DATA_FRAGMENT}
  ${FR_ATTACHED_ASSET_SUM_FRAGMENT}
  query GetFundingRequestAssociatedAssetDetailsById($fundingRequestId: Int!) {
    getFundingRequestAssociatedAssetDetailsById(fundingRequestId: $fundingRequestId) {
      status
      message
      summaryData {
        ...FRAttachedAssetSumFragment
      }
      detailsData {
        ...RawAssetDetailsDataFragment
      }
    }
  }
`
export const GET_RECONCILE_RESULT_BY_ID = gql`
  ${FR_RECONCILE_SUMMARY_DATA_RRAGMENT}
  ${FR_RECONCILE_ASSETD_DETAILS_RRAGMENT}
  query GetReconcileResultById($batchId: Int!) {
    getReconcileResultById(assetsBatchSummaryId: $batchId) {
      status
      message
      summaryData {
        ...FRReconcileSummaryData
      }
      detailsData {
        batchId
        assets {
          ...FRReconcileAssetDetailsData
        }
      }
    }
  }

`
