interface NumericFieldFormatting {
  prefix: string
  digit: number
  minDigit?: number
}

type NumericFieldsConfig = Record<string, NumericFieldFormatting>

export const RISK_FIELDS = ['Fitch', 'Moody', 'SAndP']

export const NUMERIC_FIELDS = ['TrsFinancingRate', 'MainAccountAllocation']

export const NUM_FIELDS: NumericFieldsConfig = {
  Quantity: { prefix: '', digit: 0 },
  TraderPrice: { prefix: '$', digit: 2 }
}

export const END_ALIGN = ['Quantity', 'Price', 'Notional', 'TraderPrice', 'MarketValue']
