import React, { type FC, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { type FRDataViewerProps } from '../utils/types'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { type ColDef } from 'ag-grid-community'
import '../../../utility/globals.css'

interface RowData {
  fundingRequestCode: string
  cpNotionalQtyAllocated: string | number
}

const FRDataViewer: FC<FRDataViewerProps> = ({ frData, selectedCPId }) => {
  const gridRef = useRef<AgGridReact<RowData>>(null)

  const filteredFRData = useMemo(() => {
    return selectedCPId ? frData.filter((row) => row.cPIssuanceId === selectedCPId) : []
  }, [frData, selectedCPId])

  const columnDefs: Array<ColDef<RowData>> = [
    {
      headerName: 'Funding Request Code',
      field: 'fundingRequestCode',
      sortable: true,
      headerClass: 'text-sm font-semibold'
    },
    {
      headerName: 'Notional Allocated',
      field: 'cpNotionalQtyAllocated',
      sortable: true,
      cellStyle: { textAlign: 'right' },
      headerClass: 'text-sm font-semibold'
    }
  ]

  if (!filteredFRData || filteredFRData.length === 0) {
    return <p className="mt-4 text-gray-500">No Funding Request Data Available for Selected CP</p>
  }

  return (
    <div className="mt-4">
      <h4 className="text-md font-semibold mb-2">Funding Request Details</h4>
      <div className="ag-theme-alpine w-full rounded ag-center-cols-viewport">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={filteredFRData}
          defaultColDef={{
            flex: 1,
            resizable: true
          }}
          headerHeight={30}
          rowHeight={30}
          suppressCellFocus={true}
          domLayout="autoHeight"
        />
      </div>
    </div>
  )
}

export default FRDataViewer
