import React from 'react'
import GenericInputBox from '../../../components/generic-input-box'
import { type AllocationInputProps } from '../utils/types'
import { formatInputToDollar, parseDollarToNumberCalc } from '../../../utility/formatting'

const AllocationInput: React.FC<AllocationInputProps> = ({
  availableForAllocation,
  placeholder,
  onChange,
  onKeyDown,
  onBlur
}) => {
  const handleChange = (value: string | number) => {
    const numericValue = typeof value === 'string' ? parseDollarToNumberCalc(value) : value
    if (!isNaN(numericValue)) {
      onChange(numericValue)
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const numericValue = parseDollarToNumberCalc(e.target.value)
    const formattedValue = formatInputToDollar(numericValue)
    e.target.value = formattedValue
    onBlur?.(e)
  }

  return (
    <div className="flex items-center space-x-2">
      <label className="font-bold">Allocate: $ </label>
      <GenericInputBox
        name="allocationAmount"
        type="text"
        value={availableForAllocation}
        isNumeric
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
        placeholder={placeholder}
        className="text-right rounded-md border-gray-300 shadow-sm focus:ring-gray-100 focus:border-gray-300"
      />
    </div>
  )
}

export default AllocationInput
