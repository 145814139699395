import React, { type FC, useEffect, useState, type MouseEvent, useCallback } from 'react'
import { CP_SUBMIT_WITH_ADC_ID_CREATE } from '../../../components/graphql/mutations'
import MiniNotepad from '../../../components/notepad'
import { useMutation } from '@apollo/client'
import TextInput from '../../../components/field-input'
import { type PopupProps, type GraphData, type CPProps } from '../../../utility/types'
import { useGraphData } from '../../../useGraphData'
import {
  formatInputToDollar,
  formatDateToMMddyy,
  parseDollarToNumber,
  parseDollarToNumberCalc
} from '../../../utility/formatting'
import ActionButton from '../../../components/action-button'
import ConfirmationModal from '../../../components/confirm-popup'
import closeIcon from '../../../utility/img/cancel.png'
import FRDataViewer from './FRDataViewer'
import CPAllocationSummary from './CPAllocationSummary'
import { GET_CP_BY_ID } from '../../../components/graphql/queries'
import { type AdditionalFRData } from '../utils/types'
import { useQueryWithRetry } from '../../../hooks/useQueryWithRetry'
import CPInfo from '../../../components/cp-info'
import Message from '../../../components/message'
import ProgramExpenseMultiplierInput from './ProgramExpenseMultiplierInput'
import CalculatedFieldsDisplay from './CalculatedFieldsDisplay'
import AllocationInput from './AllocationInput'

const Popup: FC<PopupProps> = ({ show, onClose, cpId, setMessage, setMessageColor, refetch }) => {
  const formattedDate = formatDateToMMddyy(new Date())
  const [frValue, setFrValue] = useState(`${formattedDate}-`)
  const [noteText, setNoteText] = useState('')
  const [selectedPrefix1, setSelectedPrefix1] = useState('')
  const [selectedPrefix2, setSelectedPrefix2] = useState('')
  const [localErrorMessage, setLocalErrorMessage] = useState<string | null>(null)
  const [leftCptyCode, setLeftCptyCode] = useState(0)
  const [rightCptyCode, setRightCptyCode] = useState(0)
  const [graphData, setGraphData] = useState<GraphData | null>(null)
  const [loadingGraphData, setLoadingGraphData] = useState(true)
  const [createFRForApproval, { error, loading }] = useMutation(CP_SUBMIT_WITH_ADC_ID_CREATE)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [selectedCPId, setSelectedCPId] = useState<number | null>(null)
  const [allocateAmounts, setAllocateAmounts] = useState<Record<number, string>>({})
  const [tempAllocateValue, setTempAllocateValue] = useState<number>(0)
  const [cpIdList, setCpIdList] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [programExpenseMultiplier, setProgramExpenseMultiplier] = useState<number>(0)
  const [totalAllocatedAmount, setTotalAllocatedAmount] = useState(0)
  const [overrideDefault, setOverrideDefault] = useState(false)
  const [initialProgramExpenseMultiplier, setInitialProgramExpenseMultiplier] = useState(0)

  const { data: cpByIdData, QueryWithRetryComponent, loading: cpByIdLoading, error: cpByIdError } = useQueryWithRetry({
    query: GET_CP_BY_ID,
    options: {
      variables: {
        id: cpId?.toString() || '',
        data_type: '|CP|CP-FR|'
      },
      skip: !cpId
    }
  })

  const data: CPProps[] = cpByIdData?.getCPIssuanceById?.cpData || []
  const frData: AdditionalFRData[] = cpByIdData?.getCPIssuanceById?.frData || []

  const firstCPData = data[0] || {}
  const {
    tenor = 0,
    discountRateCalc = 0,
    programExpenseDefault = 0,
    programExpenseDefaultPct = 0
  } = firstCPData

  useEffect(() => {
    setProgramExpenseMultiplier(programExpenseDefaultPct)
    setInitialProgramExpenseMultiplier(programExpenseDefaultPct)
  }, [programExpenseDefaultPct])

  useEffect(() => {
    const total = Object.values(allocateAmounts).reduce((sum, amount) => {
      return sum + parseDollarToNumberCalc(amount)
    }, 0)
    setTotalAllocatedAmount(total)
  }, [allocateAmounts])

  useEffect(() => {
    if (data.length > 0) {
      setSelectedCPId(data[0].cPIssuanceId)
    }
  }, [data])

  useEffect(() => {
    if (selectedCPId !== null) {
      const defaultAllocation = allocateAmounts[selectedCPId] ?? data.find((cp) => cp.cPIssuanceId === selectedCPId)?.notionalQtyReminder ?? '0'
      setTempAllocateValue(parseDollarToNumberCalc(defaultAllocation))
    }
  }, [selectedCPId, allocateAmounts, data])

  const [loadedGraphData] = useGraphData()

  useEffect(() => {
    if (loadedGraphData?.mail) {
      setGraphData(loadedGraphData)
      setLoadingGraphData(false)
    }
  }, [loadedGraphData])

  useEffect(() => {
    const allocateCashChanged = Object.keys(allocateAmounts).some((cpId) => {
      const cp = data.find((cp) => cp.cPIssuanceId.toString() === cpId)
      if (!cp) return false
      const defaultNotionalQty = formatInputToDollar(parseDollarToNumber(cp.notionalQtyReminder || '0'), { prefix: '$' })
      return allocateAmounts[parseInt(cpId)] !== defaultNotionalQty
    })

    const programExpenseMultiplierChanged = programExpenseMultiplier !== initialProgramExpenseMultiplier

    setUnsavedChanges(
      frValue !== `${formattedDate}-` ||
      noteText !== '' ||
      allocateCashChanged ||
      programExpenseMultiplierChanged ||
      overrideDefault
    )
  }, [frValue, noteText, allocateAmounts, data, programExpenseMultiplier, overrideDefault, initialProgramExpenseMultiplier])

  const extractCpPairDetails = (cpPair: string) => {
    const [label, code] = cpPair.split('*')
    return {
      label,
      code: parseInt(code, 10)
    }
  }

  const originalValues = {
    frValue: `${formattedDate}-`,
    noteText: '',
    allocateCashV: ''
  }

  const cpPair = data.length > 0 ? data[0].cpPair : []
  const cpPairs = cpPair.map((cp) => ({
    ...cp,
    cpLeft: extractCpPairDetails(cp.cpLeft),
    cpRight: cp.cpRight.map((r) => extractCpPairDetails(r))
  }))

  const dropdown1Props = {
    options: cpPairs.map((cp) => cp.cpLeft),
    selectedOption: selectedPrefix1,
    selectedCode: leftCptyCode,
    setSelectedOption: setSelectedPrefix1,
    setSelectedCode: setLeftCptyCode
  }

  const selectedCode = dropdown1Props.selectedCode
  const matchingPair = cpPairs.find((e) => e.cpLeft.code === selectedCode) ?? { cpRight: [] }

  const dropdown2Props = {
    options: matchingPair.cpRight,
    selectedOption: selectedPrefix2,
    selectedCode: rightCptyCode,
    setSelectedOption: setSelectedPrefix2,
    setSelectedCode: setRightCptyCode
  }

  useEffect(() => {
    if (cpPairs.length > 0) {
      setSelectedPrefix1(cpPairs[0].cpLeft.label)
      setLeftCptyCode(cpPairs[0].cpLeft.code)
      if (cpPairs[0].cpRight.length > 0) {
        setSelectedPrefix2(cpPairs[0].cpRight[0].label)
        setRightCptyCode(cpPairs[0].cpRight[0].code)
      }
    }
  }, [cpPair])

  useEffect(() => {
    if (error) {
      setMessage('An error occurred while updating status')
      setMessageColor('red')
    }
  }, [error, setMessage, setMessageColor])

  const handleAllocateChange = (value: number) => {
    if (selectedCPId === null) return

    const formattedValue = formatInputToDollar(value)
    setTempAllocateValue(value)

    const newAllocateAmounts = {
      ...allocateAmounts,
      [selectedCPId]: formattedValue
    }
    setAllocateAmounts(newAllocateAmounts)

    const updatedCpIdList = data
      .filter((cp) => cp.cPIssuanceId && cp.notionalQtyReminder)
      .map((cp) => {
        const allocateAmount = parseDollarToNumber(newAllocateAmounts[cp.cPIssuanceId] || cp.notionalQtyReminder || '0')
        return `${cp.cPIssuanceId}:${allocateAmount}`
      })
      .join('|')

    setCpIdList(updatedCpIdList)
  }

  const handleNumericInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleAllocateChange(parseDollarToNumberCalc(e.currentTarget.value))
  }

  const handleNumericInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAllocateChange(parseDollarToNumberCalc(e.currentTarget.value))
    }
  }

  useEffect(() => {
    if (data.length > 0) {
      const defaultAllocations = data.reduce<Record<number, string>>((acc, cp) => {
        acc[cp.cPIssuanceId] = cp.notionalQtyReminder || '0'
        return acc
      }, {})
      setAllocateAmounts(defaultAllocations)
    }
  }, [data])

  const handleDiscardChanges = useCallback(async () => {
    setAllocateAmounts({})
    setFrValue(originalValues.frValue)
    setNoteText(originalValues.noteText)
    setUnsavedChanges(false)
    setIsConfirmationModalOpen(false)
    onClose?.()
  }, [data, originalValues, onClose])

  const handleCancelClose = useCallback(async () => {
    setIsConfirmationModalOpen(false)
  }, [])

  const stopPropagation = useCallback((event: MouseEvent) => {
    event.stopPropagation()
  }, [])

  const handleOnClose = useCallback(async () => {
    if (unsavedChanges) {
      setIsConfirmationModalOpen(true)
    } else {
      onClose?.()
    }
  }, [unsavedChanges, onClose])

  useEffect(() => {
    if (isSubmitting && cpIdList) {
      const submitFR = async () => {
        if (!graphData) {
          setMessage('User data is not available')
          setMessageColor('red')
          return
        }

        const { data: responseData } = await createFRForApproval({
          variables: {
            cp_id_list: cpIdList,
            created_by: graphData?.mail,
            fr_code: `FR-${selectedPrefix1}-${selectedPrefix2}-${frValue}`,
            note: noteText,
            left_cpty_code: leftCptyCode,
            right_cpty_code: rightCptyCode,
            program_expense_multiplier_pct: programExpenseMultiplier,
            program_expense_multiplier_override: overrideDefault ? 1 : 0
          }
        })

        const { status, error: responseError } = responseData.submitAndCreateADCFundingRequest

        setMessage(status ? 'Successfully created a FR submission' : responseError)
        setMessageColor(status ? 'green' : 'red')
        refetch()
        onClose()
        setIsSubmitting(false)
      }

      submitFR()
    }
  }, [isSubmitting, cpIdList])

  const handleAccept = useCallback(async () => {
    if (Object.keys(allocateAmounts).length === 0) {
      setLocalErrorMessage('Allocated amount cannot be 0')
      return
    }

    const hasZeroAllocation = Object.values(allocateAmounts).some(
      (amount) => parseDollarToNumberCalc(amount) === 0
    )

    if (hasZeroAllocation) {
      setLocalErrorMessage('Allocated amount CPs cannot be 0.')
      return
    }

    const hasExceededAllocation = data.some((cp) => {
      const allocatedAmount = parseDollarToNumberCalc(allocateAmounts[cp.cPIssuanceId])
      const notionalQtyReminder = parseDollarToNumberCalc(cp.notionalQtyReminder)

      if (allocatedAmount === undefined || notionalQtyReminder === undefined) {
        console.error('Undefined value detected:', { allocatedAmount, notionalQtyReminder })
        return false
      }
      return allocatedAmount > notionalQtyReminder
    })

    if (hasExceededAllocation) {
      setLocalErrorMessage('Allocated amount cannot exceed the available amount.')
      return
    }

    setLocalErrorMessage(null)
    setIsSubmitting(true)
  }, [allocateAmounts, data, setLocalErrorMessage])

  const handleProgramExpenseMultiplierChange = (value: number, overrideDefault: boolean) => {
    setProgramExpenseMultiplier(value)
    setOverrideDefault(overrideDefault)
  }

  if (!show) return null

  if (cpByIdLoading || cpByIdError) {
    return (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div
            onClick={stopPropagation}
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          >
            <div className="bg-white px-4 pt-5 pb-4">
              <div className="sm:flex sm:justify-between sm:items-start">
                <button
                  onClick={handleOnClose}
                  className="absolute top-3 right-3 transition duration-150 ease-in-out rounded-full p-2"
                >
                  <img src={closeIcon} alt="Close" width={24} height={24} />
                </button>
                <div className="w-full h-[400px] flex items-center justify-center">
                  <QueryWithRetryComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      {isConfirmationModalOpen && <div className="fixed inset-0 bg-black bg-opacity-50"></div>}
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div
          onClick={stopPropagation}
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-auto"
        >
          <div className="bg-white px-4 pt-5">
            <div className="sm:flex sm:justify-between sm:items-start">
              <button
                onClick={handleOnClose}
                className="absolute top-3 right-3 transition duration-150 ease-in-out rounded-full p-2"
              >
                <img src={closeIcon} alt="Close" width={24} height={24} />
              </button>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <div className="flex">
                  <h3 className="text-lg leading-6 font-medium text-gray-900  mr-2" id="modal-title">
                    Card Details
                  </h3>
                  {localErrorMessage && (
                    <Message
                      message={localErrorMessage}
                      color={'red'}
                      clearMessage={() => {
                        setLocalErrorMessage('')
                      }}
                    />
                  )}
                </div>
                <div className="mt-2">
                  <CPInfo data={data} selectedCPId={selectedCPId} setSelectedCPId={setSelectedCPId} />
                  {selectedCPId && (
                    <div className="space-y-4">
                      <CalculatedFieldsDisplay
                        allocationAmount={parseDollarToNumberCalc(allocateAmounts[selectedCPId] || '0')}
                        discountRate={discountRateCalc}
                        tenor={tenor}
                        programExpenseMultiplier={programExpenseDefault}
                      />
                    </div>
                  )}
                  <div className="flex-grow w-full items-center mt-2 space-x-1">
                    {selectedCPId && (
                      <AllocationInput
                        availableForAllocation={tempAllocateValue}
                        onChange={handleAllocateChange}
                        onKeyDown={handleNumericInputKeyDown}
                        onBlur={handleNumericInputBlur}
                        placeholder={
                          data.find((cp) => cp.cPIssuanceId === selectedCPId)?.notionalQtyReminder ?? '0'
                        }
                      />
                    )}
                  </div>
                  <div className="mt-2 space-y-4">
                    <ProgramExpenseMultiplierInput
                      initialValue={programExpenseDefaultPct}
                      onChange={handleProgramExpenseMultiplierChange}
                    />
                    <div className="space-y-4">
                      <h4 className="font-bold">Summary Calculated Fields:</h4>
                      <CalculatedFieldsDisplay
                        allocationAmount={totalAllocatedAmount}
                        discountRate={discountRateCalc}
                        tenor={tenor}
                        programExpenseMultiplier={programExpenseDefault}
                        isSum={true}
                      />
                    </div>
                  </div>
                  <div className="bg-white">
                    <FRDataViewer frData={frData || []} selectedCPId={selectedCPId} />
                  </div>
                  <CPAllocationSummary data={data} allocateAmounts={allocateAmounts} />
                  <TextInput
                    type="text"
                    prefix="FR-"
                    className="flex items-center my-2"
                    inputClassName="flex-grow focus:ring-gray-100 focus:border-gray-300"
                    placeholder={`${formattedDate}-`}
                    value={frValue}
                    setValue={setFrValue}
                    dropdown1={dropdown1Props}
                    dropdown2={dropdown2Props}
                  />
                  <div className="mt-3 text-center">
                    <MiniNotepad noteText={noteText} setNoteText={setNoteText} autoExpand />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ActionButton
            primaryLabel={'Generate FR'}
            secondaryLabel="Cancel"
            primaryAction={handleAccept}
            secondaryAction={async () => {
              setFrValue(originalValues.frValue)
              onClose?.()
            }}
            primaryLoading={loading}
            primaryDisabled={loadingGraphData}
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onConfirm={handleDiscardChanges}
        onCancel={handleCancelClose}
        title="Unsaved Changes"
        question="You have unsaved changes. Do you want to discard them?"
        confirmText="Discard Changes"
        cancelText="Cancel"
      />
    </div>
  )
}

export default React.memo(Popup)
