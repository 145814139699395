import { BOND_EDITABLE_FIELDS, EQUITY_EDITABLE_FIELDS } from './constant'
import type { AssetDetailsData, InputAssetData, SummaryAssetData } from '../../../utility/types'

export const transformAssetData = (data: AssetDetailsData[]) => {
  return data.flatMap((asset) => {
    const commonFields = {
      UniqueBatchId: asset.UniqueBatchId,
      BuyOrSell: asset.BuyOrSell,
      Currency: asset.Currency,
      Country: asset.Country,
      Isin: asset.Isin,
      Name: asset.Name,
      Quantity: asset.Quantity,
      Price: asset.Price
    }
    const testsFields = {
      'SecurityTypeCheck*': asset.SecurityTypeCheck,
      'NotionalExposure*': asset.NotionalExposure,
      '20DAvgVol8*': asset.AvgVol20D,
      'SinglePos>15%(cpty)*': asset.SinglePosAgainstCpty,
      'SinglePos>15%(firm)*': asset.SinglePosAgainstCapMarketFirm,
      'OutstandingShares*': asset.OutstandingShares,
      'MarketCap<$1Bn*': asset.MarketCapLessThanB,
      Overall: asset.Overall
    }
    if (asset.AssetType === 'EQTY') {
      return {
        ...commonFields,
        Ticker: asset.Ticker,
        PrimaryExchange: asset.PrimaryExchange,
        SecurityType: asset.SecurityType,
        Notional: asset.Notional,
        ...testsFields
      }
    } else if (asset.AssetType === 'BOND') {
      return {
        ...commonFields,
        TraderPrice: asset.TraderPrice,
        MarketValue: asset.MarketValue,
        MaturityDate: asset.MaturityDate,
        Fitch: asset.Fitch,
        Moody: asset.Moody,
        SAndP: asset.SAndP,
        ...testsFields
      }
    }
    return []
  })
}

export const formInputSessionData = (summaryData: SummaryAssetData) => {
  let sessionData: InputAssetData = {
    Counterparty: summaryData.Counterparty ?? '',
    TradeDate: summaryData.TradeDate ?? '',
    EffectiveDate: summaryData.EffectiveDate ?? '',
    TrsFinancingRate: summaryData.TrsFinancingRate ?? '',
    TrsFinancingRateType: summaryData.TrsFinancingRateType ?? '',
    ScheduledMaturityDate: summaryData.ScheduledMaturityDate ?? '',
    FinalSettlementDate: summaryData.FinalSettlementDate ?? '',
    LiquidityCptyTtl: summaryData.LiquidityCptyTotal ?? '',
    Liquidity: summaryData.Liquidity ?? '',
    ProjectedInitialMargin: summaryData.ProjectedInitialMargin ?? '',
    CheckTradeDate: summaryData.CheckTradeDate ?? '',
    CheckEffectiveDate: summaryData.CheckEffectiveDate ?? '',
    CheckScheduledMaturityDate: summaryData.CheckScheduledMaturityDate ?? '',
    CheckFinalSettlementDate: summaryData.CheckFinalSettlementDate ?? '',
    MainAccountAllocation: summaryData.MainAccountAllocation ?? '0',
    PortfolioId: summaryData.PortfolioId ?? ''
  }
  if (summaryData.AssetType === 'BOND') {
    sessionData = {
      ...sessionData,
      EarlyTerminationDate: summaryData.EarlyTerminationDate ?? '',
      CheckEarlyTerminationDate: summaryData.CheckEarlyTerminationDate ?? ''
    }
  }
  return sessionData
}

export function getEditableFieldsForAssetType (assetType: string) {
  switch (assetType) {
    case 'BOND':
      return BOND_EDITABLE_FIELDS
    case 'EQTY':
      return EQUITY_EDITABLE_FIELDS
    default:
      return []
  }
}
