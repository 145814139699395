import React, { useCallback, type FC, useContext } from 'react'
import { useMutation } from '@apollo/client'
import Card from '../../../components/card'
import FundingRequestPopup from '../../Ops/fr-pop-up'
import FundingRequestCard from '../../Ops/funding-request/FundingRequestCard'
import { useGraphData } from '../../../useGraphData'
import { FR_SOFT_DELETE } from '../../../components/graphql/mutations'
import { type FullFundingRequestProps } from '../utils/types'
import { type PopupPropsFR, type CardBodyCompProps, type OnActionFunction } from '../../Ops/utils/types'
import UserRoleContext from '../../../contexts/UserRoleContext'

const FundingRequestItem: FC<FullFundingRequestProps> = ({ fundingRequest, refetch, setMessage, setMessageColor, status }) => {
  const [withdrawFR, { error: withdrawError, loading: primaryLoading }] = useMutation(FR_SOFT_DELETE)
  const [graphData] = useGraphData()
  const userMail = graphData?.mail
  const userRole = useContext(UserRoleContext)
  const notTrader = !(userRole !== null && ['Treasury.Trader', 'Administrator'].includes(userRole))
  const hideButton = notTrader || status !== 'REJECT'

  const handlePrimaryAction: OnActionFunction = useCallback(async ({ fundingRequestADCId }) => {
    if (!userMail) {
      setMessage('Graph data is not available')
      setMessageColor('red')
      return
    }

    const { data: responseData } = await withdrawFR({
      variables: {
        fr_id: fundingRequestADCId,
        updated_by: userMail
      }
    })

    if (responseData?.withdrawFR) {
      const { status, error: responseError } = responseData.withdrawFR
      setMessage(status ? 'Successfully Withdrew FR, please create a new correct version of FR' : (responseError ?? 'Unknown error'))
      setMessageColor(status ? 'green' : 'red')
      refetch()
    }
  }, [userMail, withdrawFR, refetch, setMessage, setMessageColor])

  const handleSecondaryClick: OnActionFunction = async () => {
    refetch()
  }

  return (
    <Card
      showCheckBoxes={false}
      CardBodyComponent={(cardBodyProps: CardBodyCompProps) => (
        <FundingRequestCard
          data={fundingRequest}
          status={fundingRequest.status}
          {...cardBodyProps}
        />
      )}
      PopupComponent={(popupProps: PopupPropsFR) => {
        return (
          <FundingRequestPopup
            fundingRequestADCId={fundingRequest.fundingRequestADCId}
            setMessage={setMessage}
            setMessageColor={setMessageColor}
            stage={'Withdraw'}
            updateError={withdrawError}
            onPrimaryAction={handlePrimaryAction}
            onSecondaryAction={handleSecondaryClick}
            primaryLoading={primaryLoading}
            showButton={!hideButton}
            userEmail={userMail}
            {...popupProps}
          />
        )
      }}
      refetch={refetch}
    />
  )
}

export default React.memo(FundingRequestItem)
