import React, { type FC, useEffect, useState, type MouseEvent } from 'react'
import { useLazyQuery } from '@apollo/client'
import FundingRequestDetails from './FundingRequestDetails'
import { extractCPDebtIssuanceRecord, extractLoanFromLenderRecord } from '../utils/textValidations'
import type { CPDebtIssuanceRecord, LoanFromLenderRecord, FundingRequestPopupProps } from '../utils/types'
import ActionButton from '../../../components/action-button'
import Message from '../../../components/message'
import { GET_FUNDING_REQUEST_BY_ID } from '../../../components/graphql/queries'

const FundingRequestPopup: FC<FundingRequestPopupProps> = (
  {
    show,
    onClose,
    fundingRequestADCId,
    setMessage,
    setMessageColor,
    stage,
    updateError,
    onPrimaryAction,
    onSecondaryAction,
    primaryLoading,
    secondaryLoading,
    showButton = true,
    userEmail
  }) => {
  const [noteText, setNoteText] = useState<string>('')
  const [loadPopupData, { data: popupData, loading: popupLoading, error: popupError }] = useLazyQuery(GET_FUNDING_REQUEST_BY_ID)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [rejectError, setRejectError] = useState<string>('')

  useEffect(() => {
    if (show && fundingRequestADCId) {
      loadPopupData({ variables: { fundingRequestId: fundingRequestADCId, note_user: userEmail } })
        .then(() => {
          setIsDataLoaded(true)
        })
    }
  }, [show, fundingRequestADCId, loadPopupData])

  useEffect(() => {
    if (updateError ?? popupError) {
      setMessage('An error occurred while updating status or fetching data')
      setMessageColor('red')
    }
  }, [updateError, popupError, setMessage, setMessageColor])

  const clearRejectError = () => {
    setRejectError('')
  }

  useEffect(() => {
    if (noteText && rejectError) {
      clearRejectError()
    }
  }, [noteText, rejectError])

  if (!show || !isDataLoaded) return null

  const data = popupData?.getFundingRequestByID || {}

  const debtIssuanceRecord: CPDebtIssuanceRecord = extractCPDebtIssuanceRecord(data?.fundingRequestDetails?.fundingRecords)
  const loanFromLenderRecord: LoanFromLenderRecord = extractLoanFromLenderRecord(data?.fundingRequestDetails?.fundingRecords)

  const stopPropagation = (event: MouseEvent) => { event.stopPropagation() }

  const handlePrimaryClick = async () => {
    await onPrimaryAction({ actionType: 'primary', fundingRequestADCId, noteText })
    onClose()
  }

  const handleSecondaryClick = async () => {
    if (stage !== 'Withdraw' && !noteText.trim()) {
      setRejectError('Please enter a note before rejecting')
      return
    }

    clearRejectError()
    await onSecondaryAction({ actionType: 'secondary', fundingRequestADCId, noteText })
    onClose()
  }

  return (
    <div onClick={onClose} className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div onClick={stopPropagation} className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-auto">
          <div className="px-2 pt-5 pb-4 sm:p-6 sm:pb-4">
            <FundingRequestDetails
              data={data}
              debtIssuanceRecord={debtIssuanceRecord}
              loanFromLenderRecord={loanFromLenderRecord}
              isApproval={stage !== 'Withdraw'}
              noteText={noteText}
              setNoteText={setNoteText}
              shouldShowSideNotes={stage !== 'Withdraw'}
            />
          </div>
          <div className='flex justify-end'>
            {rejectError && (
                <div className="mt-2">
                  <Message
                    message={rejectError}
                    color="red"
                    clearMessage={clearRejectError}
                    className="p-2 rounded"
                  />
                </div>
            )}
            {showButton && (
            <ActionButton
              primaryLabel={stage !== 'Withdraw' ? `${stage} Approval` : 'Withdraw'}
              secondaryLabel={stage !== 'Withdraw' ? 'Reject' : 'Cancel'}
              primaryAction={handlePrimaryClick}
              secondaryAction={handleSecondaryClick}
              primaryLoading={primaryLoading ?? popupLoading}
              secondaryLoading={secondaryLoading}
            />
            )}
          </div>

        </div>
      </div>
    </div>
  )
}

export default React.memo(FundingRequestPopup)
