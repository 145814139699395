import React, { useEffect, useState } from 'react'
import CPInfo from '../../../components/cp-info'
import Info from './Info'
import type { FundingRequestDetailsProps } from '../utils/types'
import FRTestSection from './FundingRequestTest'
import MiniNotepad from '../../../components/notepad'
import ShowNotes from '../../../components/show-notes'

const FundingRequestDetails: React.FC<FundingRequestDetailsProps> = ({
  data,
  debtIssuanceRecord,
  loanFromLenderRecord,
  isApproval,
  noteText = '',
  setNoteText = () => {},
  shouldShowSideNotes = false,
  shouldShowBottomNotes = false,
  isTab = false
}) => {
  const lenderName: string = loanFromLenderRecord?.lender || ''
  const { fundingRequestDetails, noteData } = data

  const [selectedCPId, setSelectedCPId] = useState<number | null>(null)

  useEffect(() => {
    setSelectedCPId(fundingRequestDetails.cpIssuances[0].cPIssuanceId)
  }, [data])

  return (
    <>
      <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-title">
        Funding Request Details
      </h3>
      <div className='flex flex-wrap items-center'>
        <div className='flex items-center mr-4'>
          <p className='font-semibold'>From: </p>
          <p className='px-2'>{fundingRequestDetails?.fundingRecords?.companyName}</p>
        </div>
        <div className='flex items-center mr-4'>
          <p className='font-semibold'>To: </p>
          <p className='px-2'>{fundingRequestDetails?.fundingRecords?.bankName}</p>
        </div>
        <div className='flex items-center mr-4'>
          <p className='font-semibold'>Funding Request ID:</p>
          <p className='px-2 bg-yellow-200'>{fundingRequestDetails?.fundingRequestCode}</p>
        </div>
        <div className='flex items-center'>
          <p className='font-semibold'>ADC Amount:</p>
          <p className='px-2'>{fundingRequestDetails?.designatedCash}</p>
        </div>
      </div>

      <p className='font-semibold'>Agreement: </p>
      <p className='text-sm'>{fundingRequestDetails?.fundingRecords?.agreement}</p>
      <div className="flex grow flex-col xl:flex-row">
        <div className="xl:w-1/2">
          {fundingRequestDetails?.cpIssuances && (
            <div className="border-2 border-gray-200 p-2 my-2 rounded-lg flex-grow">
              <CPInfo data={fundingRequestDetails.cpIssuances} selectedCPId={selectedCPId} setSelectedCPId={setSelectedCPId} isApprovalScreen={true}/>
            </div>
          )}

          <div className='flex flex-col w-full'>
            {debtIssuanceRecord && (
              <div className="border-2 border-gray-200 p-2 my-2 rounded-lg flex-grow">
                <Info data={[debtIssuanceRecord]} title="CP Debt Issuance to Market Investors" />
              </div>
            )}

            {loanFromLenderRecord && (
              <div className="border-2 border-gray-200 p-2 my-2 rounded-lg flex-grow">
                <Info data={[loanFromLenderRecord]} title={`Loan from ${lenderName}`} />
              </div>
            )}
          </div>
        </div>

        {fundingRequestDetails?.fundingRequestTest && (
          <div className="xl:w-1/2 xl:ml-4 flex flex-col">
            <FRTestSection data={fundingRequestDetails.fundingRequestTest} />
            {!isTab && (
              isApproval
                ? (
                <MiniNotepad noteText={noteText} setNoteText={setNoteText} styleClass="w-full my-2 h-full" />
                  )
                : (
                <ShowNotes data={noteData} />
                  )
            )}
            {shouldShowSideNotes && <ShowNotes data={noteData} />}
          </div>
        )}
      </div>
      {shouldShowBottomNotes && <ShowNotes data={noteData}/>}
    </>
  )
}

export default FundingRequestDetails
