import React, { type FC, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { type CPProps } from '../../../utility/types'
import { formatInputToDollar, parseDollarToNumber } from '../../../utility/formatting'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { type ColDef } from 'ag-grid-community'
import '../../../utility/globals.css'
interface RowData {
  security: string
  amount: string
}

const CPAllocationSummary: FC<{ data: CPProps[], allocateAmounts: Record<number, string> }> = ({ data, allocateAmounts }) => {
  const gridRef = useRef<AgGridReact<RowData>>(null)

  const columnDefs: Array<ColDef<RowData>> = [
    {
      headerName: 'CP Security',
      field: 'security',
      sortable: true,
      headerClass: 'text-sm font-semibold'
    },
    {
      headerName: 'Amount',
      field: 'amount',
      sortable: true,
      valueFormatter: (params: { value: string | number }) => `$${formatInputToDollar(parseDollarToNumber(params.value))}`,
      cellStyle: { textAlign: 'right' },
      headerClass: 'text-sm font-semibold'
    }
  ]

  const rowData = useMemo(() => {
    return data.map(cp => ({
      security: cp.security || 'N/A',
      amount: allocateAmounts[cp.cPIssuanceId] || '0'
    }))
  }, [data, allocateAmounts])

  return (
    <div>
      <h4 className="text-md font-semibold my-2">Proposed Allocations Summary</h4>
      <div className="ag-theme-alpine w-full ag-center-cols-viewport">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={{
            flex: 1,
            resizable: true
          }}
          headerHeight={30}
          rowHeight={30}
          suppressCellFocus={true}
          domLayout="autoHeight"
        />
      </div>
    </div>
  )
}

export default CPAllocationSummary
