import React, { type FC } from 'react'
import { type CPProps, type CPInfoProps } from '../../utility/types'
import { formatKey } from '../../utility/formatting'
import { filterCPFields, formatFaceValue } from '../../pages/Liquid/utils/displayingCpDetailsHelpers'

const CPDetails: FC<{ cp: CPProps, isApprovalScreen: boolean }> = ({ cp, isApprovalScreen }) => {
  const filteredCP = filterCPFields(cp, isApprovalScreen)
  return (
    <div className="cp-details">
      {Object.entries(filteredCP).map(([key, value]) => (
        <div className="flex" key={key}>
          <strong className="font-semibold text-sm">{formatKey(key)}:</strong>
          <p className="text-sm text-gray-500 ml-2">{value ?? ''}</p>
        </div>
      ))}
    </div>
  )
}

const CPInfo: FC<CPInfoProps> = ({ data, selectedCPId, setSelectedCPId, isApprovalScreen = false }) => {
  const getOptionLabel = (cp: CPProps) => {
    const counterpartyName = cp.counterpartyName || 'Unknown CP'
    if (isApprovalScreen) {
      const allocatedAmount = cp.notionalQtyInUse || 0
      return `${counterpartyName} (${formatFaceValue(allocatedAmount)})`
    }
    const faceValue = cp.notionalQty || 0
    return `${counterpartyName} (${formatFaceValue(faceValue)})`
  }

  const selectedCP = data.find((cp) => cp.cPIssuanceId === selectedCPId)

  return (
    <div className="align-items-center">
      <div className="flex">
        <h4 className="font-bold">CP Info:</h4>
        <div className="flex space-x-4 ">
          <select
            value={selectedCPId ?? ''}
            onChange={(e) => {
              const selectedId = parseInt(e.target.value, 10)
              setSelectedCPId?.(selectedId)
            }}
            className="p-1 ml-2 border border-gray-300 rounded-lg shadow-sm focus:border-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-300 w-64 truncate"
          >
            <option value="" disabled>Select CP</option>
            {data?.map((cp) => (
              <option key={cp.cPIssuanceId} value={cp.cPIssuanceId}>
                {getOptionLabel(cp)}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedCP && <CPDetails cp={selectedCP} isApprovalScreen={isApprovalScreen} />}
    </div>
  )
}

export default CPInfo
