import React, { useEffect, useState } from 'react'
import { formatKey } from '../../utility/formatting'
import type { InputAssetData, InputBoxProps } from '../../utility/types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { NUMERIC_FIELDS } from '../records-table/utilities/constant'

const InputBox: React.FC<InputBoxProps> = ({
  fields,
  inputData,
  setInputData,
  isDataReady = false,
  readOnly = true,
  setMessage,
  setMessageColor,
  setHasFlag
}) => {
  const today = new Date()
  const [fieldErrors, setFieldErrors] = useState <Partial<InputAssetData>>({})

  useEffect(() => {
    checkAndUpdateFieldIssues()
  }, [inputData])

  useEffect(() => {
    setHasFlag?.(Object.keys(fieldErrors).length > 0)
  })

  const checkAndUpdateFieldIssues = () => {
    const newFieldErrors: Partial<Record<string, string>> = {}
    fields.forEach(field => {
      if (field.type === 'date') {
        const checkFieldName = `Check${field.name}` as keyof InputAssetData
        const checkValue = inputData[checkFieldName]
        if (typeof checkValue === 'string' && checkValue !== 'BUSINESS-DAY') {
          const [reason, nextAvailableDate] = checkValue.split('->')
          newFieldErrors[field.name as keyof InputAssetData] = `This is ${reason}, change it to ${nextAvailableDate}`
        }
        if (inputData[field.name as keyof InputAssetData] === '1900-01-01') {
          newFieldErrors[field.name as keyof InputAssetData] = 'Please provide a valid date'
        }
      } else {
        const dataValue = inputData[field.name as keyof InputAssetData]

        if (!dataValue) {
          newFieldErrors[field.name as keyof InputAssetData] = 'Please provide data'
        } else if (field.name === 'TrsFinancingRate') {
          const numericValue = parseFloat(dataValue)
          if (inputData.TrsFinancingRateType === 'Fixed') {
            if (numericValue <= 0 || numericValue > 100) {
              newFieldErrors[field.name as keyof InputAssetData] = 'Please provide data with the range of 0-100'
            }
          } else if (inputData.TrsFinancingRateType === 'FEDL01' || inputData.TrsFinancingRateType === 'SOFRRATE') {
            if (numericValue < 0 || numericValue > 10000) {
              newFieldErrors[field.name as keyof InputAssetData] = 'Please provide data with the range of 0-10000'
            }
          }
        } else if (NUMERIC_FIELDS.includes(field.name)) {
          const numericValue = parseFloat(dataValue)
          if (numericValue < 0 || numericValue > 100) {
            newFieldErrors[field.name as keyof InputAssetData] = 'Please provide data with the range of 0-100'
          }
        }
        if (typeof dataValue === 'string') {
          const decimalPlaces = (dataValue.split('.')[1] || '').length
          if (decimalPlaces > 3) {
            newFieldErrors[field.name as keyof InputAssetData] = 'Input cannot have more than 3 decimal places'
          }
        }
      }
    })

    setFieldErrors(newFieldErrors)
  }

  const handleChange = (value: string, name: string) => {
    if (NUMERIC_FIELDS.includes(name)) {
      const decimalPlaces = (value.split('.')[1] || '').length
      if (decimalPlaces > 3) {
        setMessage?.('Input cannot have more than 3 decimal places.')
        setMessageColor?.('red')
        return
      }
    }

    const newData = { ...inputData, [name]: value }

    if (name === 'TradeDate' || name === 'EffectiveDate') {
      const tradeDate = newData.TradeDate
      const effectiveDate = newData.EffectiveDate

      if (tradeDate && effectiveDate) {
        const tradeDateObj = new Date(`${tradeDate}T00:00`)
        const effectiveDateObj = new Date(`${effectiveDate}T00:00`)

        if (effectiveDateObj < tradeDateObj) {
          setMessage?.('Effective date must be on or after the trade date.')
          setMessageColor?.('red')
          return
        }
      }
    }
    setMessage?.('')
    setMessageColor?.('green')

    setInputData?.(newData)
  }

  const handleDateChange = (date: Date | null, name: string) => {
    if (date) {
      handleChange(date.toISOString().split('T')[0], name)
    }
  }

  const isWeekday = (date: Date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  return (
    <div className="flex flex-wrap justify-center mx-3 overflow-visible">
      {fields.map((field) => {
        const isTrsFinancingRate = field.name === 'TrsFinancingRate'
        const isBpsField = isTrsFinancingRate && (inputData.TrsFinancingRateType === 'FEDL01' || inputData.TrsFinancingRateType === 'SOFRRATE')
        const dateValue = inputData[field.name as keyof InputAssetData]
        const dateObject = dateValue ? new Date(`${dateValue}T00:00`) : null
        const error = fieldErrors[field.name as keyof InputAssetData]
        const borderStyle = error ? 'border-red-500 border-2 focus:border-red-300 focus:ring-red-200' : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'

        return (
          <div key={field.name} className="flex items-center max-w-1/4 text-sm relative group">
            <label className="block font-medium text-gray-700 ml-2 mr-2">
              {formatKey(field.name)}:
            </label>
            <div className="flex-1 my-1">
              {!field.readOnly && !readOnly
                ? (
                    field.type === 'date'
                      ? (
                      <DatePicker
                        todayButton="Today"
                        selected={dateObject}
                        onChange={date => { handleDateChange(date, field.name) }}
                        filterDate={isWeekday}
                        minDate={today}
                        showYearDropdown
                        disabled={!isDataReady}
                        className={`w-full text-sm rounded-md shadow-sm focus:ring-opacity-100 ${borderStyle} relative`}
                        dateFormat="yyyy-MM-dd"
                        yearDropdownItemNumber={20}
                        scrollableYearDropdown
                      />
                        )
                      : isTrsFinancingRate
                        ? (
                        <div className="flex items-center">
                          <select
                            name="TrsFinancingRateType"
                            value={inputData.TrsFinancingRateType ?? 'Fixed'}
                            onChange={e => { handleChange(e.target.value, 'TrsFinancingRateType') }}
                            disabled={!isDataReady || readOnly}
                            className={'mr-1 block text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'}
                          >
                            <option value="Fixed">Fixed</option>
                            <option value="FEDL01">FEDL01</option>
                            <option value="SOFRRATE">SOFRRATE</option>
                          </select>
                          {isBpsField && <span className="mr-1">+</span>}
                          <input
                            type={field.type}
                            name={field.name}
                            id={field.name}
                            value={inputData[field.name as keyof InputAssetData] ?? ''}
                            onChange={e => { handleChange(e.target.value, e.target.name) }}
                            placeholder={field.placeholder}
                            disabled={!isDataReady || readOnly}
                            className={`[&::-webkit-inner-spin-button]:appearance-none w-full block text-sm rounded-md border-gray-300 shadow-sm ${borderStyle} focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 pr-6`}
                            inputMode={isBpsField ? 'numeric' : 'text'}
                            pattern={isBpsField ? '[0-9]*' : undefined}
                          />
                          {isTrsFinancingRate && (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              {isBpsField ? 'bps' : '%' }
                            </span>
                          )}
                        </div>
                          )
                        : (
                        <input
                          type={field.type}
                          name={field.name}
                          id={field.name}
                          value={inputData[field.name as keyof InputAssetData] ?? ''}
                          onChange={e => { handleChange(e.target.value, e.target.name) }}
                          placeholder={field.placeholder}
                          disabled={!isDataReady || readOnly}
                          className={`w-full block text-sm rounded-md border-gray-300 shadow-sm ${borderStyle} focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                        />
                          )
                  )
                : (
                  <span
                    className={`text-gray-700 flex items-center px-1 ${
                      inputData[field.name as keyof InputAssetData] === 'Pass'
                        ? 'bg-green-100 text-green-700'
                        : inputData[field.name as keyof InputAssetData] === 'Fail'
                        ? 'bg-red-100 text-red-700'
                        : 'bg-gray-100'
                    }`}
                  >
                    {inputData[field.name as keyof InputAssetData]}
                    {isTrsFinancingRate && (isBpsField ? ' bps' : '%')}
                    {isTrsFinancingRate && inputData.TrsFinancingRateType && ` (${inputData?.TrsFinancingRateType})`}
                  </span>
                  )}
              {error && !readOnly && (
                <div className="overflow-visible absolute -top-1 right-0 bg-red-500 text-white text-xs rounded px-2 py-1 transform translate-y-[-40%] translate-x-[90%] opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
                  {error}
                </div>
              )}
            </div>
          </div>
        )
      })}
      </div>
  )
}

export default InputBox
