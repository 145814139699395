import { gql } from '@apollo/client'

export const CP_FRAGMENT = gql`
  fragment CPFragment on CP {
    cPIssuanceId
    status
    security
    price
    tradeDate
    settleDate
    cusip
    discountRate
    tenor
    mMYD
    counterpartyName
    issuerName
    notionalQty
    proceeds
    maturityDate
    cPDiscountAmount
    cpPair {
      cpLeft
      cpRight
    }
    notionalQtyInUse
    notionalQtyReminder
    programExpenseDefault
    programExpenseDefaultPct
    discountRateCalc
  }
`

export const ISSUER_FRAGMENT = gql`
  fragment IssuerFragment on Issuer {
    displayName
  }
`

export const NOTE_FRAGMENT = gql`
  fragment NoteFragment on Note {
    by
    text
  }
`

export const BLOTTER_FRAGMENT = gql`
  fragment BlotterFragment on UploadRecord {
    dealer
    tradeDate
    settleDate
    structureMtyDate
    cusip
    notional
    cleanPrice
    priceAccrued
    hairCut
    dirtyPrice
    repoRate
    tRSFixedRate
    inflationFactor
    secType
    side
  }
`

export const ASSET_FRAGMENT = gql`
  fragment AssetFragment on AssetData {
    Dealer
    TradeDate
    SettleDate
    Cusip
    Notional
    CleanPrice
    PriceAccrued
    DirtyPrice
    MarketValue
    SectionType
  }
`

export const FUNDING_RECORD_FRAGMENT = gql`
  fragment FundingRecordFragment on FundingRecord {
    issuer
    bankName
    fundingDate
    maturityDate
    tenorDays
    faceAmount
    proceeds
    discountRateTotal
    discountAmount
    moodysRating
    sAndPRating
    companyName
    principal
    interestRate
    interestAmount
    agreement
    amountDueAtMaturity
  }
`

export const FUNDING_REQUEST_TEST_FRAGMENT = gql`
  fragment FundingRequestTestFragment on FundingRequestTest {
    assetCfSufficiencyTest
    approvedTransactionAgtTest
    transactionCptyRatingTest
    transactionCptyMinRating
    transactionCptyRatingsOverride
    assetCoRepTest
    opGuidelinesAssetSpecificReqComplianceTest\
    assetPassFail
  }
`

export const FUNDING_REQUEST_SUM_FRAGMENT = gql`
  fragment FundingRequestSumFragment on FundingRequestSummaryData {
    status
    fundingRequestCode
    fundingRequestADCId
    cpIssuancesId
    timeSubmitted
    submittedBy
    portfolioId
  }
`

export const FUNDING_REQUEST_FRAGMENT = gql`
  fragment FundingRequestFragment on FundingRequest {
    designatedCash
    fundingRequestCode
    cpIssuances {
      ...CPFragment
    }
    fundingRecords {
      ...FundingRecordFragment
    }
    fundingRequestTest{
      ...FundingRequestTestFragment
    }
    notes {
      ...NoteFragment
    }
  }
  ${CP_FRAGMENT}
  ${NOTE_FRAGMENT}
  ${FUNDING_RECORD_FRAGMENT}
  ${FUNDING_REQUEST_TEST_FRAGMENT}
`

export const RAW_ASSET_SUM_FRAGMENT = gql`
  fragment RawAssetSumFragment on AssetSums {
    AssetType
    Name
    BatchFileName
    BatchUniqueId
    BatchId
    MarketDataComplete
    OmsDataComplete
    RiskCalcComplete
    ClientAddressEmail
    CreatedDate
    BasketOverall
    ApprovalStatus
    FundingRequestId
    FundingRequestCode
    CounterParty
    AssetsDisable
    TotalEstimatedNotional
    TotalAssetCount
    TotalQtyFI
    TotalQtyEqty
    ReconcileIndicator
    ReconcileCount
    TransactionType
    PortfolioId
  }
`

export const RAW_ASSET_DETAILS_SUMMARY_FRAGMENT = gql`
  fragment RawAssetDetailsSummaryFragment on AssetDetailsSummaryData {
    Counterparty
    CreatedDate
    TradeDate
    EffectiveDate
    TrsFinancingRate
    TrsFinancingRateType
    ScheduledMaturityDate
    FinalSettlementDate
    LiquidityCptyTotal
    ProjectedInitialMargin
    CheckTradeDate
    CheckEffectiveDate
    CheckScheduledMaturityDate
    CheckFinalSettlementDate
    MainAccountAllocation
    EarlyTerminationDate
    CheckEarlyTerminationDate
    MarketDataComplete
    OmsDataComplete
    RiskCalcComplete
    BasketOverall
    CounterpartyId
    FundingRequestCode
    Liquidity
    PortfolioId
  }
`

export const RAW_ASSET_DETAILS_DATA_FRAGMENT = gql`
  fragment RawAssetDetailsDataFragment on AssetDetailData {
    AssetType
    UniqueBatchId
    BuyOrSell
    Currency
    Isin
    Country
    Name
    Quantity
    Price
    SecurityTypeCheck
    NotionalExposure
    AvgVol20D
    SinglePosAgainstCpty
    SinglePosAgainstCapMarketFirm
    OutstandingShares
    MarketCapLessThanB
    Overall
    Ticker
    PrimaryExchange
    Notional
    SecurityType
    TraderPrice
    MarketValue
    MaturityDate
    CountryRegion
    Fitch
    Moody
    SAndP
  }
`

export const RAW_ASSET_NOTE_FRAGMENT = gql`
  fragment RawAssetNoteDataFragment on AssetNoteData {
    Note
    CreatedDate
    CreatedBy
    EventName
  }
`

export const FR_ATTACHED_ASSET_SUM_FRAGMENT = gql`
  fragment FRAttachedAssetSumFragment on AssetSummaryData {
    assetCashflows {
        TRSIncome
        InvestmentInterest
        ADC
        AssetAcquisitionCost
        Total
      }
      liabilityCashflows {
        DebtCost
        FinanceCost
        Total
      }
      overall {
        NetExcess
        RegulatoryCapitalTest
        FRCode
      }
  }
`

export const FR_RECONCILE_SUMMARY_DATA_RRAGMENT = gql`
  fragment FRReconcileSummaryData on ReconcileSummary {
    ReconcileID
    ReconcileNote
    TRSRateInit
    TRSRateAffirm
    trs_rate_mismatch
    TradeDateInit
    TradeDateAffirm
    trade_date_mismatch
    EffectiveDateInit
    EffectiveDateAffirm
    effective_date_mismatch
    MaturityDateInit
    MaturityDateAffirm
    maturity_date_mismatch
    FinalSettlementDateInit
    FinalSettlementDateAffirm
    final_settlement_date_mismatch
  }
`

export const FR_RECONCILE_ASSETD_DETAILS_RRAGMENT = gql`
  fragment FRReconcileAssetDetailsData on ReconcileAssetDetails {
    InstrumentId
    Description
    Cusip
    ISIN
    Country
    Ticker
    QuantityInit
    QuantityAffirm
    SideInit
    SideAffirm
    SecurityMasterIdMatch
    quantity_mismatch
    side_mismatch
    security_mismatch
  }
`
