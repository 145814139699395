import React, { useState, useEffect, useMemo } from 'react'
import Card from '../../../components/card'
import { GET_CP_BY_DATES, GET_ALL_ISSUERS } from '../../../components/graphql/queries'
import { type CPProps } from '../../../utility/types'
import Popup from '../cp-pop-up'
import Message from '../../../components/message'
import CardBody from '../cards-body'
import { type CardBodyCompProps, type Issuer } from '../utils/types'
import { tradeDateMenu } from '../utils/types'
import { formatIssuerName } from '../../../utility/formatting'
import { useQueryWithRetry } from '../../../hooks/useQueryWithRetry'

const RequestCard = () => {
  const today = new Date()

  const [filters, setFilters] = useState({
    selectedTradeDate: 'Past 3 Days',
    selectedIssuer: 'All Issuers',
    fromDate: new Date().toISOString().split('T')[0],
    toDate: new Date().toISOString().split('T')[0]
  })

  useEffect(() => {
    if (!filters.selectedTradeDate) return

    const newFromDate = new Date(today)
    switch (filters.selectedTradeDate) {
      case 'Today':
        newFromDate.setDate(today.getDate())
        break
      case 'Past 3 Days':
        newFromDate.setDate(today.getDate() - 3)
        break
      case 'Past 7 Days':
        newFromDate.setDate(today.getDate() - 7)
        break
      case 'Past 30 Days':
        newFromDate.setDate(today.getDate() - 30)
        break
      default:
        newFromDate.setDate(today.getDate() - 3)
    }

    setFilters((prev) => ({
      ...prev,
      fromDate: newFromDate.toISOString().split('T')[0],
      toDate: today.toISOString().split('T')[0]
    }))
  }, [filters.selectedTradeDate])

  const { data, refetch, QueryWithRetryComponent, loading, error } = useQueryWithRetry({
    query: GET_CP_BY_DATES,
    options: {
      variables: {
        from_date: filters.fromDate,
        to_date: filters.toDate
      }
    }
  })

  const Issuers = useQueryWithRetry({ query: GET_ALL_ISSUERS })

  const [selectedCards, setSelectedCards] = useState<number[]>([])
  const [mergeModeIssuer, setMergeModeIssuer] = useState<string | null>(null)
  const [showCheckBoxes, setShowCheckBoxes] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [buttonName, setButtonName] = useState('Select Cards')
  const [message, setMessage] = useState<string | null>(null)
  const [messageColor, setMessageColor] = useState<'red' | 'green' | 'yellow'>('green')

  const filteredData = useMemo(() => {
    if (!data?.getCPIssuanceByDates?.cpData) return []
    let filteredArray = [...data.getCPIssuanceByDates.cpData]
    if (filters.selectedIssuer !== 'All Issuers') {
      filteredArray = filteredArray.filter((cp: CPProps) =>
        cp.issuerName.includes(formatIssuerName(filters.selectedIssuer))
      )
    }
    if (showCheckBoxes && mergeModeIssuer) {
      filteredArray = filteredArray.filter((cp: CPProps) =>
        cp.issuerName === mergeModeIssuer
      )
    }
    return filteredArray
  }, [data, filters.selectedIssuer, showCheckBoxes, mergeModeIssuer])

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a: CPProps, b: CPProps) => b.cPIssuanceId - a.cPIssuanceId)
  }, [filteredData])

  const handleTradeDateButtonClick = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event?.target?.value
    if (!selectedValue) return
    setFilters((prev) => ({ ...prev, selectedTradeDate: selectedValue }))
  }

  const handleIssuerButtonClick = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event?.target?.value
    if (!selectedValue) return
    setFilters((prev) => ({ ...prev, selectedIssuer: selectedValue }))
  }

  const handleSelectButtonClick = () => {
    if (!showCheckBoxes) {
      setShowCheckBoxes(true)
      setButtonName('Merge Cards')
      setMergeModeIssuer(null)
    } else {
      if (selectedCards.length > 0) {
        setShowPopup(true)
      }
      setShowCheckBoxes(false)
      setButtonName('Select Cards')
      setMergeModeIssuer(null)
    }
  }

  const handlePopupCancel = () => {
    setSelectedCards([])
    setShowPopup(false)
  }

  const handleCardSelect = (issuerName: string) => {
    if (!mergeModeIssuer) {
      setMergeModeIssuer(issuerName)
    }
  }

  const handleCardClick = (cPIssuanceId: number) => {
    if (!showCheckBoxes) {
      setSelectedCards([cPIssuanceId])
      setShowPopup(true)
    }
  }

  if (error) return <QueryWithRetryComponent />

  return (
    <>
      <div className="inline-flex items-center w-full p-4">
        <select
          id="issuers"
          value={filters.selectedIssuer || 'All Issuers'}
          onChange={handleIssuerButtonClick}
          className="text-white bg-gray-800 hover:bg-black focus:ring-2 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
        >
          {Issuers.data?.getAllIssuers?.map((issuer: Issuer) => (
            <option key={issuer.displayName} value={issuer.displayName}>
              {issuer.displayName}
            </option>
          ))}
        </select>
        <select
          id="tradeDate"
          value={filters.selectedTradeDate || 'Past 3 Days'}
          onChange={handleTradeDateButtonClick}
          className="text-white bg-gray-800 hover:bg-black focus:ring-2 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
        >
          {tradeDateMenu?.map((tradeDate) => (
            <option key={tradeDate} value={tradeDate}>
              {tradeDate}
            </option>
          ))}
        </select>
        {message && (
          <Message
            message={message}
            color={messageColor}
            clearMessage={() => { setMessage(null) }}
          />
        )}
        <button
          onClick={handleSelectButtonClick}
          className="text-white bg-gray-800 hover:bg-black focus:ring-2 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 ml-auto"
        >
          {buttonName}
        </button>
      </div>
      <div className="flex flex-wrap justify-around mt-8">
        {loading
          ? (
          <p>Loading data...</p>
            )
          : sortedData.length > 0
            ? (
                sortedData.map((cp: CPProps, index: number) => (
            <Card
              refetch={refetch}
              key={index}
              isCp
              cpIssuanceId={cp.cPIssuanceId}
              selectedCards={selectedCards}
              setSelectedCards={setSelectedCards}
              showCheckBoxes={showCheckBoxes}
              onClick={showCheckBoxes ? undefined : () => { handleCardClick(cp.cPIssuanceId) }}
              CardBodyComponent={(cardBodyProps: CardBodyCompProps) => (
                <CardBody data={cp} {...cardBodyProps} />
              )}
              issuerName={cp.issuerName}
              onSelectCard={handleCardSelect}
            />
                ))
              )
            : (
          <p>No data found</p>
              )}
      </div>
      {showPopup && (
        <Popup
          show={showPopup}
          cpId={selectedCards}
          onClose={handlePopupCancel}
          setMessage={setMessage}
          setMessageColor={setMessageColor}
          refetch={refetch}
        />
      )}
    </>
  )
}

export default RequestCard
