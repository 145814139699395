import React, { type ChangeEvent, useState, type FC } from 'react'
import { type ProgramExpenseMultiplierInputProps } from '../utils/types'
import GenericInputBox from '../../../components/generic-input-box'

const ProgramExpenseMultiplierInput: FC<ProgramExpenseMultiplierInputProps> = ({
  initialValue,
  onChange
}) => {
  const [currentValue, setCurrentValue] = useState(initialValue)

  const [overrideDefault, setOverrideDefault] = useState(false)

  const handleInputChange = (value: string | number) => {
    const numericValue = typeof value === 'string' ? parseFloat(value.replace(/[^\d.]/g, '')) : value

    if (!isNaN(numericValue)) {
      setCurrentValue(numericValue)
      onChange(numericValue, overrideDefault)
    }
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    setOverrideDefault(isChecked)

    onChange(currentValue, isChecked)
  }

  return (
    <div className="flex items-center space-x-2">
      <label className="font-bold">Program Expense Multiplier:</label>
      <GenericInputBox
        name="programExpenseMultiplier"
        type="text"
        value={currentValue}
        isNumeric
        onChange={(value) => {
          handleInputChange(value)
        }}
        className="text-right rounded-md border-gray-300 shadow-sm focus:ring-gray-100 focus:border-gray-300 max-w-[80px] min-w-[40px] w-auto"
      />
      <span>%</span>
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={overrideDefault}
          onChange={handleCheckboxChange}
          className="form-checkbox"
        />
        <span>Override Program Default</span>
      </label>
    </div>
  )
}

export default ProgramExpenseMultiplierInput
