import React, { type FC } from 'react'
import { type AssetsCheckerBodyProps } from './utils/types'
import StatusIndicator from '../../components/status-indicator'
import { formatInputToDollar } from '../../utility/formatting'

const AssetsCheckerBody: FC<AssetsCheckerBodyProps> = ({ data, onClick, ttlNotioanl, reconcileIndicator, reconcileCount }) => {
  const {
    BatchFileName, Name, MarketDataComplete, OmsDataComplete, RiskCalcComplete,
    ClientAddressEmail, CreatedDate, ApprovalStatus, CounterParty,
    TotalAssetCount, FundingRequestCode, BasketOverall, TransactionType, PortfolioId
  } = data
  const reconcileState = reconcileIndicator === 1
    ? 'Waiting-Recon'
    : reconcileIndicator === 4
      ? 'Failed-Recon'
      : reconcileIndicator === 5
        ? 'Reconciled'
        : null

  return (
    <div onClick={onClick} className="w-full p-2 bg-white shadow hover:shadow-lg transition-shadow duration-300 ease-in-out cursor-pointer">
      <div className="space-y-1">
        <Detail title="Name:" content={Name} />
        <Detail title="Transaction Type:" content={TransactionType} />
        <Detail title="Assets:" content={BatchFileName} />
        <Detail title="Counterparty:" content={CounterParty} />
        <Detail title="Received at:" content={CreatedDate} />
        <Detail title="From:" content={ClientAddressEmail} />
        <Detail title="Total Notional:" content={formatInputToDollar(ttlNotioanl, { prefix: '$' })}/>
        <Detail title="Number of Assets:" content={TotalAssetCount} />
        <Detail title="Portfolio Id:" content={PortfolioId} />
        <Detail title="Inter-co Loan:" content={FundingRequestCode} hidden={!FundingRequestCode} style={'bg-lime-200'}/>
      </div>
      <div className='flex justify-between items-center text-sm mt-2'>
        <div className='felx flex-grow'>
          <div className='flex flex-row space-x-0.5'>
            <StatusIndicator status={MarketDataComplete} label="Market" />
            <StatusIndicator status={OmsDataComplete} label="Pos" />
            <div className={`text-${BasketOverall ? 'black' : 'red'}-500`}><StatusIndicator status={RiskCalcComplete} label="Risk" /></div>
          </div>
          {reconcileState && <div className='flex flex-auto space-x-2'>
              <h2 className='text-sm font-semibold'>{reconcileState}</h2> {reconcileIndicator === 1 && <h2 className='text-sm font-semibold'>{reconcileCount}</h2>}
            </div>}
        </div>
        <div>
          <h2 className='text-sm font-semibold border p-0.5'>{ApprovalStatus}</h2>
        </div>
      </div>
    </div>
  )
}

const Detail: FC<{ title: string, content: string | number, hidden?: boolean, style?: string }> = ({ title, content, hidden = false, style = '' }) => (
  <div className={`flex ${hidden ? 'invisible' : 'visible'}`}>
    <span className='font-semibold pr-1'>{title}</span>
    <div className={`truncate overflow-hidden hover:overflow-visible hover:whitespace-normal ${style}`}>
      {content}
    </div>
  </div>
)

export default AssetsCheckerBody
