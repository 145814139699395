import React, { useEffect, useState, type FC } from 'react'
import Info from '../../Ops/fr-pop-up/Info'
import { type OverviewTabProps } from './type'
import { useMutation } from '@apollo/client'
import { AMEND_ASSET_STATUS } from '../../../components/graphql/mutations'
import { amendFRAssetStatus } from '../../Cap-Trader/utils/assetBucketsHelpers'
import { useGraphData } from '../../../useGraphData'
import ActionButton from '../../../components/action-button'
import ShowNotes from '../../../components/show-notes'
import MiniNotepad from '../../../components/notepad'
import Message from '../../../components/message'

const OverviewTab: FC<OverviewTabProps> = ({
  fundingRequestId,
  frSummaryData,
  notes,
  stage,
  onClose,
  setMessage,
  setMessageColor
}) => {
  const [isPrimaryMutating, setIsPrimaryMutating] = useState<boolean>(false)
  const [isSecondaryMutating, setIsSecondaryMutating] = useState<boolean>(false)
  const [noteText, setNoteText] = useState('')
  const [rejectError, setRejectError] = useState('')
  const [amendFRAssetStatusMutation] = useMutation(AMEND_ASSET_STATUS)
  const [graphData] = useGraphData()

  const clearRejectError = () => {
    setRejectError('')
  }

  useEffect(() => {
    if (noteText && rejectError) {
      clearRejectError()
    }
  }, [noteText, rejectError])

  if (!frSummaryData) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-blue-500"></div>
      </div>
    )
  }

  const { assetCashflows, liabilityCashflows, overall } = frSummaryData

  const handleClick = async (approvalStatus: string) => {
    if (fundingRequestId) {
      await amendFRAssetStatus({
        amendFRAssetStatusMutation,
        fundingRequestId,
        approvalStatus,
        processRequested: 'CapMarket',
        updatedBy: graphData?.mail,
        note: noteText,
        setMessage,
        setMessageColor
      })
    }
  }

  const handleRejectClick = async () => {
    if (!noteText.trim()) {
      setRejectError('Please enter a note before rejecting')
      return
    }

    setIsSecondaryMutating(true)
    const approvalStatus = stage === 'Initial' ? 'PROG-COMPL-REJECTED' : 'FULL-PROG-COMPL-REJECTED'
    await handleClick(approvalStatus)
    setIsSecondaryMutating(false)
    onClose?.()
  }

  const handleProceedClick = async () => {
    setIsPrimaryMutating(true)
    const approvalStatus = stage === 'Initial' ? 'PROG-COMPL-APPROVED' : 'FULL-PROG-COMPL-APPROVED'
    await handleClick(approvalStatus)
    setIsPrimaryMutating(false)
    onClose?.()
  }

  return (
    <div className="p-6 bg-white rounded-lg">
      <div className="mb-4 flex items-center gap-2">
        <h3 className="text-xl font-bold text-gray-900">Funding Request Overview:</h3>
        <span className="text-l text-gray-700 bg-yellow-100 p-1">{overall.FRCode}</span>
      </div>
      <div className="flex flex-col xl:flex-row space-y-4 xl:space-y-0 xl:space-x-6 h-full">
        <div className="xl:w-1/3 space-y-4">
          <div className="border border-gray-300 p-4 rounded-lg shadow-sm flex-grow">
            <Info data={[assetCashflows]} title="Asset Cashflows" />
          </div>
          <div className="border border-gray-300 p-4 rounded-lg shadow-sm flex-grow">
            <Info data={[liabilityCashflows]} title="Liability Cashflows" />
          </div>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span className="font-semibold">Net Excess:</span>
              <span>{overall.NetExcess}</span>
            </div>
            <div className="flex justify-between">
              <span className="font-semibold">Regulatory Capital Test:</span>
              <span>{overall.RegulatoryCapitalTest}</span>
            </div>
          </div>
        </div>
        <div className="xl:w-1/3 flex flex-col space-y-4">
          <div className="flex min-w-0">
            <ShowNotes data={notes} defaultExpand={true}/>
          </div>
          <div className="flex min-w-0">
            <MiniNotepad noteText={noteText} setNoteText={setNoteText} styleClass="w-full mb-5 h-full" />
          </div>
        </div>
      </div>
      <div className="mt-6 flex justify-end space-x-4">
        {rejectError && (
          <div className="mt-2">
            <Message
              message={rejectError}
              color="red"
              clearMessage={clearRejectError}
              className="p-2 rounded"
            />
          </div>
        )}
        <ActionButton
          primaryLabel="Approve"
          secondaryLabel="Reject"
          primaryAction={handleProceedClick}
          secondaryAction={handleRejectClick}
          primaryLoading={isPrimaryMutating}
          secondaryLoading={isSecondaryMutating}
          primaryDisabled={isPrimaryMutating || isSecondaryMutating}
          secondaryDisabled={isPrimaryMutating || isSecondaryMutating}
        />
      </div>
    </div>
  )
}

export default React.memo(OverviewTab)
