export function formatToDollar (amount: number): string {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
}

export const formatKey = (key: string) => {
  // replace camelCase with space-separated words and capitalize the first letter of each word
  let formattedKey = key
    .replace(/(And)(?=[A-Z])/, '&')
    .replace(/(?<=\B.{2,})(?=[A-Z])|(?<=\B[A-Z])(?=[A-Z][a-z])/g, ' ')
    .replace(/\b[a-z]/g, str => str.toUpperCase())

  // Add "($M)" if the key is "notional"
  if (key.toLowerCase() === 'notional') {
    formattedKey += ' ($M)'
  }

  return formattedKey
}

export const parseCurrencyString = (value: string): number => {
  const numberString = value.replace(/[$,]/g, '') // Remove $ and ,
  return parseFloat(numberString)
}

export const parseDollarToNumber = (value: number | string, removeDecimal: boolean = false) => {
  let stringValue = typeof value === 'number' ? value.toString() : value
  stringValue = stringValue.replace(/[^\d.-]/g, '')

  if (removeDecimal) {
    stringValue = stringValue.split('.')[0]
  }
  return stringValue
}

export const parseDollarToNumberCalc = (value: number | string) => {
  const stringValue = typeof value === 'number' ? value.toString() : value

  return parseFloat(stringValue.replace(/[^\d.-]/g, ''))
}

export const formatInputToDollar = (value: string | number, formatting: { prefix?: string, digit?: number, minDigit?: number } = {}) => {
  let { prefix = '', digit = 2, minDigit = 2 } = formatting

  if (minDigit > digit) {
    minDigit = digit
  }

  let formattedValue = parseDollarToNumber(value)

  const numericValue = parseFloat(formattedValue)

  if (!isNaN(numericValue)) {
    formattedValue = numericValue.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: minDigit,
      maximumFractionDigits: digit
    })
  }

  return prefix + formattedValue
}

export function formatDateToMMddyy (date: Date): string {
  const formatted = new Intl.DateTimeFormat('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' }).format(date)
  return formatted.replace(/[/]/g, '')
}

export function formatEmailToName (noteby: string): string {
  const formatted = noteby.split('@')[0]
  return formatted.replace('.', ' ')
}

export function stringToFloat (s: string): number {
  const sanitizedString = s.replace(/,/g, '')
  return parseFloat(sanitizedString)
}

export const sanitizeData = (data: unknown): unknown => {
  if (Array.isArray(data)) {
    return data.map(sanitizeData)
  } else if (typeof data === 'object' && data !== null) {
    const { __typename: _, ...rest } = data as Record<string, unknown>
    for (const key in rest) {
      rest[key] = sanitizeData(rest[key])
    }
    return rest
  }
  return data
}

export const formatDate = (date: string): string => {
  if (!date) return ''
  const d = new Date(date)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  month = month.length < 2 ? '0' + month : month
  day = day.length < 2 ? '0' + day : day

  return [month, day, year].join('/')
}

export const formatIssuerName = (issuerName: string) => {
  return issuerName[0].toUpperCase() + issuerName.slice(1).toLowerCase()
}

export const formatNumberWithCommas = (value: string): string => {
  const num = parseFloat(value)
  if (!isNaN(num)) {
    return num.toLocaleString('en-US', {
      maximumFractionDigits: 0
    })
  }
  return value
}
