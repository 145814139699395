import { type CPProps } from '../../../utility/types'
import { type KeyboardEvent, type FocusEvent } from 'react'

export interface CardBodyProps {
  data: CPProps
  onClick?: () => void
}

export interface PopupPropsLiquid {
  show: boolean
  onClose: () => void
}

export interface CardBodyCompProps {
  onClick?: () => void
}

export interface Group {
  firstGroup: string[]
  secondGroup: string[]
}

export interface Issuer {
  displayName: string
}

export type MappingTable = Record<string, Group | undefined>

export const tradeDateMenu = [
  'Today',
  'Past 3 Days',
  'Past 7 Days',
  'Past 30 Days'
]
export interface AdditionalFRData {
  cPIssuanceId: number
  fundingRequestCode: string
  cpNotionalQtyAllocated: string
}
export interface FRDataViewerProps {
  frData: AdditionalFRData[]
  selectedCPId?: number | null
}

export interface AllocationInputProps {
  availableForAllocation: number
  onChange: (newValue: number) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  placeholder?: string
}

export interface CalculatedFieldsDisplayProps {
  allocationAmount: number
  discountRate: number
  tenor: number
  programExpenseMultiplier: number
  isSum?: boolean
}

export interface ProgramExpenseMultiplierInputProps {
  initialValue: number
  onChange: (initialValue: number, useAsDefault: boolean) => void
}
