// export const useLazyQueryWithRetry = ({ query, retryLimit = 5, retryDelay = 1000, countDown = 5, options = {} }: useQueryWithRetryProps) => {
//   const [retryCount, setRetryCount] = useState(0)
//   const [displayErrorMessage, setDisplayErrorMessage] = useState(false)
//   const [countdown, setCountDown] = useState(retryLimit)

//   const [executeQuery, { loading, data, error, refetch }] = useLazyQuery(query, options)

//   useEffect(() => {
//     let intervalId: ReturnType<typeof setInterval> | undefined
//     if (error && retryCount < retryLimit) {
//       setDisplayErrorMessage(true)
//       setCountDown(countDown)
//       intervalId = setInterval(() => {
//         setCountDown((prevCount) => {
//           if (prevCount === 1) {
//             clearInterval(intervalId)
//             setRetryCount(retryCount + 1)
//             refetch(options?.variables)
//             return 0
//           } else {
//             return prevCount - 1
//           }
//         })
//       }, retryDelay)
//     } else if (retryCount === retryLimit && error) {
//       setDisplayErrorMessage(false)
//     } else {
//       setRetryCount(0)
//     }
//     return () => {
//       clearInterval(intervalId)
//     }
//   }, [retryCount, retryLimit, error, refetch, options?.variables])

//   const QueryWithRetryComponent = () => {
//     if (loading) {
//       return <p>Loading...</p>
//     }
//     if (displayErrorMessage && retryCount < retryLimit) {
//       return (
//         <div>
//           <p>Error fetching the data, retry in {countdown} seconds...</p>
//           <p>{retryLimit - retryCount} retries left.</p>
//         </div>
//       )
//     } else {
//       return <p>You are getting {error?.message}. Please contact the technology team.</p>
//     }
//   }

//   return { executeQuery, data, refetch, QueryWithRetryComponent, loading, error }
// }

import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { type useQueryWithRetryProps } from '../utility/types'

export const useQueryWithRetry = ({
  query,
  retryLimit = 3,
  retryDelay = 1000,
  countDown = 5,
  options = {}
}: useQueryWithRetryProps) => {
  const [retryCount, setRetryCount] = useState(0)
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false)
  const [countdown, setCountDown] = useState(countDown)

  const { loading, data, error, refetch } = useQuery(query, options)

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | undefined

    if (error && retryCount < retryLimit) {
      setDisplayErrorMessage(true)

      intervalId = setInterval(() => {
        setCountDown((prevCount) => {
          if (prevCount === 1) {
            clearInterval(intervalId)
            setRetryCount((prevRetryCount) => prevRetryCount + 1)
            refetch(options?.variables)
            return countDown
          } else {
            return prevCount - 1
          }
        })
      }, retryDelay)
    } else if (retryCount >= retryLimit && error) {
      setDisplayErrorMessage(false)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [error, retryCount, retryLimit, refetch, options?.variables, countDown, retryDelay])

  useEffect(() => {
    if (options?.variables) {
      refetch(options.variables)
    }
  }, [options?.variables])

  const QueryWithRetryComponent = () => {
    if (loading) {
      return <p>Loading...</p>
    }
    if (displayErrorMessage && retryCount < retryLimit) {
      return (
        <div>
          <p>Error fetching the data, retry in {countdown} seconds...</p>
          <p>{retryLimit - retryCount} retries left.</p>
        </div>
      )
    } else if (error) {
      return <p>You are getting {error?.message}. Please contact the technology team.</p>
    }
    return null
  }

  return { data, refetch, QueryWithRetryComponent, loading, error }
}
