import React, { useState, type FC } from 'react'
import RecordTable from '../../components/records-table'
import { TRADER_INPUT_FIELDS_MAP } from '../../utility/constant'
import InputBox from '../../components/inputbox'
import ActionButton from '../../components/action-button'
import closeIcon from '../../utility/img/cancel.png'
import { RISK_BOND_EDITABLE_FIELDS } from './utils/constants'
import type { RiskAssetViewerProps } from './utils/types'
import { useRiskAssetDetails } from './hooks/useRiskAssetDetails'
import Message from '../../components/message'
import ConfirmationModal from '../../components/confirm-popup'
import ShowNotes from '../../components/show-notes'
import MiniNotepad from '../../components/notepad'
import { formatInputToDollar } from '../../utility/formatting'
import ReconcileSummaryList from '../Cap-Trader/reconcile-components/ReconcileSummaryList'
import ReconcileDetails from '../Cap-Trader/reconcile-components/ReconcileDetails'

const RiskAssetViewer: FC<RiskAssetViewerProps> = ({
  show,
  onClose,
  batchId,
  batchUniqueId,
  setMessage,
  assetType,
  setMessageColor,
  updateError,
  status,
  basketOverall,
  fundingRequestId,
  fundingRequestCode,
  ttlNotional,
  ttlQuantity,
  reconcileIndicator,
  reconcileCount
}) => {
  const [isReconcilePopupOpen, setIsReconcilePopupOpen] = useState(false)
  const [selectedRecordIndex, setSelectedRecordIndex] = useState<number>(0)

  const {
    records,
    setRecords,
    inputData,
    setInputData,
    isMutating,
    isSecondaryMutating,
    loading,
    error,
    QueryWithRetryComponent,
    enableEdit,
    recalcMessage,
    recalcMessageColor,
    setRecalcMessage,
    dataChanged,
    noteChanged,
    handleCancel,
    handlePrimaryActionWithValidation,
    handleSecondaryClick,
    handleSave,
    preparingAsset,
    getPrimaryLabel,
    handleDiscard,
    isConfirmationDialogActive,
    setIsConfirmationDialogActive,
    handleSaveExit,
    handleCloseIconClick,
    riskNote,
    setRiskNote,
    displayingNotes,
    reconciliationResultData,
    loadingReconciliation,
    errorReconciliation
  } = useRiskAssetDetails(
    {
      batchId,
      batchUniqueId,
      assetType,
      setMessage,
      setMessageColor,
      status,
      basketOverall,
      onClose,
      updateError,
      testDetails: true,
      fundingRequestId,
      reconcileIndicator,
      reconcileCount
    }
  )

  if (!show) return null
  if (loading || error) return <QueryWithRetryComponent />
  if (loadingReconciliation) return <p>Loading reconciliation data...</p>
  if (errorReconciliation) return <p>{errorReconciliation.message}</p>

  const approvedStatuses = new Set(['TRADER-APPROVED-EXCEPTION', 'TRADER-APPROVED', 'ASSET-READY'])
  const enableAction = approvedStatuses.has(status)

  const handleOpenReconciliationPopup = () => {
    setIsReconcilePopupOpen(true)
  }

  const handleCloseReconciliationPopup = () => {
    setIsReconcilePopupOpen(false)
    setSelectedRecordIndex(0)
  }

  return (
    <>
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-auto">
      {isConfirmationDialogActive && (
        <div className="fixed inset-0 bg-black bg-opacity-50"></div>
      )}
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
      <div className="relative bg-white rounded-lg shadow-xl transform transition-all sm:max-w-[70%] sm:my-8 w-auto max-h-[80%]">
      <div className='flex flex-row justify-between items-center px-4 pt-2'>
        <button onClick={handleCloseIconClick} className="transition duration-150 ease-in-out rounded-full p-2">
          <img src={closeIcon} alt='Close' width={24} height={24}/>
        </button>
        <div className='flex-grow mx-4 overflow-hidden flex justify-center items-center'>
          {recalcMessage && (
            <div className="overflow-auto whitespace-pre-wrap break-words">
              <Message message={recalcMessage} color={recalcMessageColor} clearMessage={() => { setRecalcMessage('') }} />
            </div>
          )}
        </div>
        {!preparingAsset && enableEdit && (<button
          onClick={handleSave}
          className={`transition ease-in-out bg-green-600 hover:bg-green-700 text-white text-sm py-2 px-3 rounded-full ${(dataChanged || noteChanged) ? '' : 'opacity-50 cursor-not-allowed'}`}
          disabled={!(dataChanged || noteChanged)}
        >
          {isMutating
            ? (<div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>)
            : 'Recalc'
          }
        </button>)}
      </div>
      <div className="max-h-[70vh]">
        <InputBox
            fields={TRADER_INPUT_FIELDS_MAP(assetType)}
            inputData={inputData}
            setInputData={setInputData}
            isDataReady={false}
            readOnly={true} />
          <div className='flex flex-col overflow-auto'>
            <div className={fundingRequestCode ? 'px-4' : 'p-4 pt-0'}>
              <RecordTable
                records={records}
                setRecords={setRecords}
                isDataReady={true}
                editableFields={RISK_BOND_EDITABLE_FIELDS}
                showEdit={enableEdit}
              />
              <div className='flex flex-col xl:flex-row space-y-2.5 xl:space-y-0 xl:space-x-2.5 h-full'>
                <div className="flex flex-1 min-w-0">
                  <ShowNotes data={displayingNotes} />
                </div>
                <div className="flex flex-1 min-w-0">
                  <MiniNotepad
                    noteText={riskNote}
                    setNoteText={setRiskNote}
                    disabled={!enableAction}
                  />
                </div>
                <div className='flex flex-1 flex-col space-y-2.5 xl:space-y-0 xl:flex-row pt-2 min-w-fit'>
                  <div className="flex">
                    Notional MV:
                    <div className='ml-1 mr-2'>
                      <span className='bg-red-100 pl-2 pr-2'>{formatInputToDollar(ttlNotional ?? 0, { prefix: '$' })}</span>
                    </div>
                  </div>
                  <div className="flex">
                    Total Quantity:
                    <div className='ml-1 mr-2'>
                      <span className='bg-red-100 pl-2 pr-2'>{formatInputToDollar(ttlQuantity ?? 0, { digit: 0 })}</span>
                    </div>
                  </div>
                  <div className="flex">
                    Num of assets:
                    <div className='ml-1'>
                      <span className='bg-yellow-100 pl-2 pr-2'>
                        {records.filter(record => !record.isRemoved).length}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(enableAction || enableEdit) && <div className="flex flex-auto space-x-4 items-center">
              <div className={`${fundingRequestCode ? 'visible' : 'invisible'} flex flex-1 p-5`}>
                Inter-company Loan:
                <div className="ml-1 pl-2 pr-2 mr-2">
                  {fundingRequestCode}
                </div>
              </div>
              {reconcileIndicator === 1 && reconcileCount > 0 && (
                <button onClick={handleOpenReconciliationPopup} className="text-red-500 underline">
                  Reconcile Details: {reconcileCount}
                </button>
              )}
              <div className='flex flex-2 flex-end'>
                <ActionButton
                  primaryLabel={getPrimaryLabel}
                  secondaryLabel={ preparingAsset ? 'Cancel' : 'Reject'}
                  primaryAction={handlePrimaryActionWithValidation}
                  secondaryAction={preparingAsset ? handleCancel : handleSecondaryClick}
                  primaryLoading={isMutating}
                  secondaryLoading={isSecondaryMutating}
                  primaryDisabled={preparingAsset ? !(dataChanged || noteChanged) : dataChanged}
                />
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>

    {isReconcilePopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="overflow-auto bg-white rounded-lg shadow-lg w-3/4 h-3/4 p-4 relative max-h-fit">
            <div className="flex justify-between p-4">
              <button onClick={handleCloseReconciliationPopup} className="transition duration-150 ease-in-out rounded-full">
                <img src={closeIcon} alt="Close" width={24} height={24} />
              </button>
              <h2 className="text-xl font-bold text-justify">Reconciliation Details</h2>
              <ReconcileSummaryList
                resultData={reconciliationResultData?.summaryData ?? []}
                setSelectedRecordIndex={setSelectedRecordIndex}
                selectedRecordIndex={selectedRecordIndex}
              />
            </div>
            <ReconcileDetails
              summaryData={reconciliationResultData?.summaryData[selectedRecordIndex]}
              detailsData={reconciliationResultData?.detailsData}
            />
          </div>
        </div>
    )}

    <ConfirmationModal
      isOpen={isConfirmationDialogActive}
      onClose={handleDiscard}
      onConfirm={handleSaveExit}
      onCancel={async () => { setIsConfirmationDialogActive(false) }}
      question="You have some unsaved changes, do you want to save them?"
      title="Changes made"
      confirmText="Save"
      cancelText="Discard"
      primaryLoading={isMutating}
      secondaryLoading={isSecondaryMutating}
    />
    </>
  )
}

export default RiskAssetViewer
