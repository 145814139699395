import { type CPProps } from '../../../utility/types'

export const filterCPFields = (cp: CPProps, isApprovalScreen?: boolean) => {
  const fieldsToDisplay = {
    security: cp.security,
    cusip: cp.cusip,
    tradeDate: cp.tradeDate,
    settleDate: cp.settleDate,
    maturityDate: cp.maturityDate,
    'Tenor (Days )': cp.tenor,
    issuerName: cp.issuerName,
    price: cp.price,
    'mm-yield': cp.mMYD,
    discountRate: cp.discountRate,
    // condictonal isApprovalScreen to format counterpartyName
    counterpartyName: isApprovalScreen
      ? cp.counterpartyName
      : `${cp.counterpartyName} (${formatFaceValue(cp.notionalQtyReminder)})`,
    // condictonal isApprovalScreen set the key name for notionalQty
    ...(isApprovalScreen
      ? { notionalQty: cp.notionalQty }
      : { totalCpIssued: cp.notionalQty })
  }

  return fieldsToDisplay
}

export const formatFaceValue = (amount: number | string): string => {
  let numericAmount: number
  if (typeof amount === 'string') {
    numericAmount = parseFloat(amount.replace(/[^0-9.-]/g, ''))
  } else {
    numericAmount = amount
  }

  if (isNaN(numericAmount)) {
    return 'None'
  }

  if (numericAmount >= 1000000) {
    return `$${(numericAmount / 1000).toLocaleString()}K`
  }
  return `$${numericAmount.toLocaleString()}`
}
