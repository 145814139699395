import React, { useEffect, useState, type ChangeEvent, type FocusEvent } from 'react'
import { type GenericInputBoxProps } from '../../utility/types'
import { formatInputToDollar, parseDollarToNumber } from '../../utility/formatting'

const GenericInputBox: React.FC<GenericInputBoxProps> = ({
  name,
  type = 'text',
  value = '',
  placeholder = '',
  isEditable = true,
  isNumeric = false,
  isAmount = false,
  disabled = false,
  minWidth = '120px',
  className = '',
  onChange,
  onFocus,
  onKeyDown,
  onBlur
}) => {
  const [inputValue, setInputValue] = useState<string | number>(value)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const formatValue = (value: string) => {
    if (isAmount) {
      return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 0
      }).format(parseFloat(value))
    } else if (isNumeric) {
      return formatInputToDollar(parseDollarToNumber(value))
    }
    return value
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = isNumeric || isAmount ? e.target.value.replace(/[^\d.]/g, '') : e.target.value
    setInputValue(newValue)
    onChange?.(newValue, name)
  }

  const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (isNumeric || isAmount) {
      const numericValue = e.target.value.replace(/[^0-9.]/g, '')
      setInputValue(numericValue)
    } else {
      onFocus?.(e, name)
    }
  }

  const handleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (isNumeric || isAmount) {
      const formattedValue = formatValue(e.target.value)
      setInputValue(formattedValue)
    }
    onBlur?.(e, name)
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((isNumeric || isAmount) && e.key === 'Enter') {
      const formattedValue = formatValue(e.currentTarget.value)
      setInputValue(formattedValue)
    }
    onKeyDown?.(e, name)
  }

  return (
    <input
      type={type}
      name={name}
      id={name}
      value={inputValue}
      placeholder={placeholder}
      disabled={!isEditable || disabled}
      className={className}
      style={{ minWidth }}
      onChange={handleInputChange}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      onKeyDown={handleInputKeyDown}
    />
  )
}

export default GenericInputBox
