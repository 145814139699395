import { type InputField } from './types'

export const DEFAULT_INPUT_WRAP_DATA = {
  Counterparty: '',
  TradeDate: '',
  TrsFinancingRate: '',
  TrsFinancingRateType: 'Fixed',
  EffectiveDate: '',
  ScheduledMaturityDate: '',
  FinalSettlementDate: '',
  LiquidityCptyTtl: '',
  Liquidity: '',
  ProjectedInitialMargin: '',
  MainAccountAllocation: ''
}

export const ASSETHEADERS = ['Instrument Type', 'Dealer', 'Trade Date', 'currency', 'notional($M)', 'sectionType', 'side', 'Isin', 'Country']

export const EQUITYHEADERS = ['Buy Or Sell', 'Created Date', 'Currency', 'Isin', 'Country', 'Long Name', 'Quantity', 'Ticker', 'Price', 'Primary Exchange', 'Security Type', 'Notional ($M)']

const BASE_TRADER_INPUT_FIELDS: InputField[] = [
  { name: 'Counterparty', placeholder: 'N/A', readOnly: true, type: 'text' },
  { name: 'TrsFinancingRate', placeholder: 'TRS Financing Rate', readOnly: false, type: 'number' },
  { name: 'TradeDate', placeholder: 'Trade Date', readOnly: false, type: 'date' },
  { name: 'EffectiveDate', placeholder: 'Effective Date', readOnly: false, type: 'date' },
  { name: 'ScheduledMaturityDate', placeholder: 'Maturity Date', readOnly: false, type: 'date' },
  { name: 'FinalSettlementDate', placeholder: 'Final Settlement Date', readOnly: false, type: 'date' },
  { name: 'LiquidityCptyTtl', placeholder: 'N/A', readOnly: true, type: 'text' },
  { name: 'Liquidity', placeholder: 'N/A', readOnly: true, type: 'text' },
  { name: 'ProjectedInitialMargin', placeholder: 'N/A', readOnly: true, type: 'text' },
  { name: 'MainAccountAllocation', placeholder: 'Main Account Allocation', readOnly: false, type: 'number' },
  { name: 'PortfolioId', placeholder: 'N/A', readOnly: true, type: 'text' }
]

const TRADERBONDINPUTFIELDS: InputField[] = [
  ...BASE_TRADER_INPUT_FIELDS,
  { name: 'EarlyTerminationDate', placeholder: 'Early Termination Date', readOnly: false, type: 'date' }
]

const TRADEREQTYINPUTFIELDS: InputField[] = [...BASE_TRADER_INPUT_FIELDS]

export function TRADER_INPUT_FIELDS_MAP (assetType?: string): InputField[] {
  switch (assetType) {
    case 'BOND':
      return TRADERBONDINPUTFIELDS
    case 'EQTY':
      return TRADEREQTYINPUTFIELDS
    default:
      return []
  }
}
